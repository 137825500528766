/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const Commerce = (() => {
	let lastItemAdded = '';
	let lastActionTaken = '';
	let cartMessage = '';
	// let baseURL =
	// 	'https://www-eu-uat4-global-vuse.non-prod.marketing.bat.net/graphql';
	let baseURL = 'http://localhost:8010/proxy';
	let storeID;

	const cartUpdate = new CustomEvent('cart-update');
	const mixedCart = new CustomEvent('mixed-cart');
	const cartAction = new CustomEvent('cart-action');
	const loginAction = new CustomEvent('login-action');

	const LOYALTY_CACHE_TIME = 900000;

	// private method(s)
	const _constructor = () => {
		// Get Product Catalog
		/* eslint-disable-next-line no-use-before-define */
		// _getProductCatalog('velo').then((response) => {
		// 	/* eslint-disable-next-line no-console */
		// 	console.log('All Products', response);
		// });

		// Set authored graphql endpoint
		if (typeof graphql !== 'undefined' && graphql !== '') {
			baseURL = graphql;
		}

		/* eslint-disable-next-line no-use-before-define */
		_cartCookieCheck();

		window.addEventListener('commerce-interfaces-loaded', () => {
			/* eslint-disable-next-line no-use-before-define */
			if (baseURL && window.location.href.indexOf('/si/sl') < 0) {
				_checkLogin();
			}
			
			/* eslint-disable-next-line no-use-before-define */
			_setSessionStorage('strength-lookup-table', []);
			window.dispatchEvent(new Event('strength-table-available'));

			if (window.location.href.indexOf('/app/') > -1) {
				if (typeof dataLayer !== 'undefined') {
					if (window.Native) {
						dataLayer.push({
							value: 'Mobile App',
							event: 'Application Type'
						});
					} else {
						dataLayer.push({
							value: 'Web App',
							event: 'Application Type'
						});
					}
				}
			} else {
				if (typeof dataLayer !== 'undefined') {
					dataLayer.push({
						value: 'website',
						event: 'Application Type'
					});
				}
			}
		});
	};

	// Check for cart cookie
	const _cartCookieCheck = () => {
		const cartCookie = document.cookie.match(
			`(^|;) ?commerce-cart-id=([^;]*)(;|$)`
		);
		const cartData = localStorage.getItem('commerce-cart-storage');

		// Clear local cart storage if no cart cookie is found.
		if (!cartCookie && cartData) {
			localStorage.removeItem('commerce-cart-storage');
		}
	};

	// Set Session Storage
	const _setSessionStorage = (id, value) => {
		// Put the object into storage
		sessionStorage.setItem(id, JSON.stringify(value));

		return true;
	};

	// Get Session Storage
	const _getSessionStorage = (id) => {
		const storageObject = sessionStorage.getItem(id);

		// Retrieve the object from storage
		return storageObject;
	};

	// Fetch GET Request
	const _makeGetRequest = async (query, variables) => {
		try {
			if (window.parent.location.href.indexOf('/editor.html/') > -1) {
				return null;
			}
		} catch (e) {
			console.log(e);
		}
		// Clean strings of new lines and unnecessary space
		const queryString = query.replace(/\s\s+/g, ' ');
		const variablesString = variables
			? variables.replace(/\s\s+/g, ' ')
			: '';

		const queryEncodeString = `?query=${encodeURIComponent(queryString)}`;
		const variablesEncodeString = `&variables=${encodeURIComponent(
			variablesString
		)}`;

		delete requestHeader.Authorization;
		const url =
			baseURL +
			queryEncodeString +
			(variables ? variablesEncodeString : '');

		const request = await fetch(url, {
			method: 'GET',
			headers: requestHeader,
		})
			.then((response) => response.json())
			.then((response) => {
				// Console error messages and return
				if (response.errors && response.errors.length) {
					response.errors.map((error) => {
						/* eslint-disable-next-line no-console */
						console.log('Response failed: ', error.message);
						return false;
					});
				}

				return response;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});

		return request;
	};

	// Fetch POST Request
	const _makePostRequest = async (query, omitCredentials) => {

		try {
			if (window.parent.location.href.indexOf('/editor.html/') > -1) {
				return null;
			}
		} catch (e) {
			console.log(e);
		}

		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			requestHeader.Authorization = `Bearer ${authToken}`;
		} else {
			delete requestHeader.Authorization;
		}

		const request = await fetch(baseURL, {
			method: 'POST',
			headers: requestHeader,
			body: query,
			credentials: omitCredentials? 'omit' : 'include'
		})
			.then((response) => response.json())
			.then((response) => {
				// Console error messages and return
				if (response.errors && response.errors.length) {
					response.errors.map((error) => {
						/* eslint-disable-next-line no-console */
						console.log('Response failed: ', error.message);
						/* eslint-disable-next-line no-console */
						console.log(
							'Response DebugMessage: ',
							error.debugMessage
						);
						return false;
					});
				}

				return response;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
				/* eslint-disable-next-line no-console */
				console.log('Response DebugMessage: ', error.debugMessage);
			});

		return request;
	};

	const _getProductAggregations = async (categoryId) => {
		const requestBody = `{
			products(filter: { category_id: { eq: "${categoryId}" } }, currentPage: 1, pageSize: 100) {
			items {
				categories {
					name
					id
					uid
				}
			}
			aggregations {
				attribute_code
				label
				count
				options {
				  count
				  label
				  value
				}
			  }
			  total_count
			  page_info {
				total_pages
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	const _getStrengthTable = async () => {
		const strengthLookupTable = _getSessionStorage('strength-lookup-table');
		const strengthTableAvailable = new Event('strength-table-available');

		if (strengthLookupTable && strengthLookupTable !== 'undefined') {
			window.dispatchEvent(strengthTableAvailable);
		} else {
			const requestBody = `{
				customAttributeMetadata(
					attributes: [{ attribute_code: "strength", entity_type: "catalog_product" }]
				) {
					items {
						attribute_code
						attribute_options {
							value
							label
						}
						entity_type
					}
				}
			}`;

			await _makeGetRequest(requestBody).then((response) => {
				const attributeOptions =
					response.data.customAttributeMetadata.items[0]
						.attribute_options;

				_setSessionStorage('strength-lookup-table', attributeOptions);

				window.dispatchEvent(strengthTableAvailable);

				return response;
			});
		}
	};

	const _getCategoryList = async (categoryId, customAttributes) => {
		const requestBody = `{
			${customAttributes
				? `
			customAttributeMetadata(
				attributes: [${customAttributes}]
			) {
				items {
					attribute_code
					attribute_type
					entity_type
					input_type
					attribute_options {
					value
					label
					}
				}
			},`
				: ``
			}
			categoryList (
				filters: {
					category_uid: { eq: "${categoryId}" }
				}
			) {
				${categoryListInterface}
			}
		}`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get Product Catalog
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCatalog = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';
			if (categories) {
				if (categories.length > 1) {
					categories.forEach((category) => {
						string += `"${category}",`;
					});
					return `
						category_id: { 
							in: [${string}] 
						}
					`;
				} else if (categories.length === 1) {
					string += `"${categories[0]}"`;
					return `
						category_id: { 
							eq: ${string} 
						}
					`;
				}
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
								...acc,
								[cur.filterProp]: `"${cur.filterValue}",`,
							}
							: {
								...acc,
								[cur.filterProp]: (acc[
									cur.filterProp
								] += `"${cur.filterValue}",`),
							};
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		const requestBody = `query getProductCatalog {
			products(
				filter: { 
					${createCategoriesString(categoryIds)}
					${createPropertiesString(filterProps)}
					${createSKUstring(productSKU)}
					${createPriceRangeString()}
				}
				${createSearchString()}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${(!!sortParam && sortParam.sortDir) || 'ASC'
			}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			) {
				items 	{
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
					shipping_group
				}
				total_count
				page_info {
					current_page
					page_size
					total_pages
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};


	const _getProductsPrices = async (
		categoryIds,
		sortParam,
		currentPage,
		pageSize,
	) => {
		createCategoriesString = (categories) => {
			let string = '';
			if (categories) {
				if (categories.length > 1) {
					categories.forEach((category) => {
						string += `"${category}",`;
					});
					return `
						category_id: { 
							in: [${string}] 
						}
					`;
				} else if (categories.length === 1) {
					string += `"${categories[0]}"`;
					return `
						category_id: { 
							eq: ${string} 
						}
					`;
				}
			}
			return string;
		};

		const requestBody = `{
			products(
				filter: { 
					${createCategoriesString(categoryIds)}
				}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${(!!sortParam && sortParam.sortDir) || 'ASC'
			}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			) {
				items {
					sku			
					__typename
					
					... on ConfigurableProduct {
						
						variants {
							product {
								uid
								name
								sku
								lowest30days_price
								price_range{
									minimum_price {
										regular_price {
											value
											currency
										}
										discount {
											amount_off
											percent_off
										}
										final_price {
											value
											currency
										}
									}
								}
								
							}
							
						}
					}
					price_range {
						minimum_price {
							regular_price {
								value
								currency
							}
							discount {
								amount_off
								percent_off
							}
							final_price {
								value
							}
						}
					}
				}
				total_count
				page_info {
					current_page
					page_size
					total_pages
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			return response;
		});

		return request;
	};

	const _getProductPrice= async (
		productSku
	) => {

		const requestBody = `{
			products(
                filter: { sku: {eq: "${productSku}"} }
			) {
				items {
					sku			
					__typename
					
					... on ConfigurableProduct {
						
						variants {
							product {
								uid
								name
								sku
								lowest30days_price
								price_range{
									minimum_price {
										regular_price {
											value
											currency
										}
										discount {
											amount_off
											percent_off
										}
										final_price {
											value
											currency
										}
									}
								}
								
							}
							
						}
					}
					price_range {
						minimum_price {
							regular_price {
								value
								currency
							}
							discount {
								amount_off
								percent_off
							}
							final_price {
								value
							}
						}
					}
				}
				total_count
				page_info {
					current_page
					page_size
					total_pages
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			return response;
		});

		return request;
	}; //da

	

	// Get Product Category List with uid
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCategoryList = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';

			if (categories) {
				categories.forEach((category) => {
					string += `"${category}",`;
				});
				return `
					category_uid: { 
						eq: ${string} 
					}
				`;
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
								...acc,
								[cur.filterProp]: `"${cur.filterValue}",`,
							}
							: {
								...acc,
								[cur.filterProp]: (acc[
									cur.filterProp
								] += `"${cur.filterValue}",`),
							};
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		const requestBody = `{
			categoryList(
			  filters: {
				${createCategoriesString(categoryIds)}
				${createPropertiesString(filterProps)}
				${createSKUstring(productSKU)}
				${createPriceRangeString()}
			  }
			)
			{
			  products(
				${createSearchString()}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${(!!sortParam && sortParam.sortDir) || 'DESC'
			}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			  ) {
				items {
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
				}
				total_count
				page_info {
				  current_page
				  page_size
				  total_pages
				}
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get One Product
	const _getProduct = async (productID) => {
		// Catch no productId.
		if (!productID) {
			return Promise.resolve('Need Product Id.');
		}

		const requestBody = `query getProductDetail {
            products(
                filter: { sku: {eq: "${productID}"} }
            ) {
                items {
					loyalty_minimum_threshold
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
					${relatedProductsInterface}
                }
            }
			
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _getProductUrlKey = async (productIDs) => {
		// Catch no productId.
		if (!productIDs) {
			return Promise.resolve('Need Product Ids.');
		}

		const requestBody = `{
            products(
                filter: { sku: {eq: "${productIDs}"} }
            ) {
                items {
					sku
					url_key
                }
            }
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	/* Add Simple Product to Cart */
	const _addSimpleProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addProductsToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
		) {
			addProductsToCart(
				cartId: $cartId
				cartItems: [
					{ 
						quantity: $quantity, 
						sku: $sku 
						${cartObject.personalisation
				? `personalisation: {
									front_font: "${cartObject.front_font}"
									front_text: "${cartObject.front_text}"
									front_orientation: "${cartObject.front_orientation}"
									back_text: "${cartObject.back_text}"
									back_font: "${cartObject.back_font}"
									back_orientation: "${cartObject.back_orientation}"
									icon: ""
									pattern: "${cartObject.pattern}"
								}`
				: ''
			}
					}
				]
			) {
				cart {
					items {
						id
						product {
							id
							name
							sku
						}
						${cartObject.productDelivery
				? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
				: ''
			}
						${cartObject.personalisation
				? `personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}`
				: ''
			}
						quantity
					}
				}

				user_errors {
					code
					message
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		return request;
	};

	/* Add Virtual Product to Cart */
	const _addVirtualProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addVirtualProductToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
		) {
			addVirtualProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							data: { 
								quantity: $quantity, 
								sku: $sku
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							id
							name
							sku
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		return request;
	};

	/* Add Configurable Product to Cart */
	const _addConfigurableProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addConfigurableProductToCart(
			$cartId: String!
			$parentSku: String!
			$sku: String!
			$quantity: Float!
		) {
			addConfigurableProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							parent_sku: $parentSku,
							data: { 
								quantity: $quantity, 
								sku: $sku
								${cartObject.personalisation
				? `personalisation: {
											front_font: "${cartObject.front_font}"
	  										front_text: "${cartObject.front_text}"
											front_orientation: "${cartObject.front_orientation}"
											back_text: "${cartObject.back_text}"
											back_font: "${cartObject.back_font}"
											back_orientation: "${cartObject.back_orientation}"
											icon: ""
											pattern: "${cartObject.pattern}"
										}`
				: ''
			}
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							id
							name
							sku
						}
						${cartObject.productDelivery
				? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
				: ''
			}
						${cartObject.personalisation
				? `personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}`
				: ''
			}
						... on ConfigurableCartItem {
							configurable_options {
								option_label
							}
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			parentSku: cartObject.familySku,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Bundle Product to Cart */
	const _addBundleProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addProductsToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
			$bundleOptions: [ID]!
		) {
			addProductsToCart(
				cartId: $cartId
				cartItems: [
					{
						sku: $sku, 
						quantity: $quantity
						selected_options: $bundleOptions
					}
				]
			) {
				cart {
					items {
						id
						quantity
						product {
							sku
						}
						... on BundleCartItem {
							bundle_options {
								id
								label
								type
								values {
									id
									label
									price
									quantity
								}
							}
						}
					}
				}
				user_errors {
					code
					message
				}
			}
		}`;

		const bundleOptions = [] 
		cartObject.bundleOptions.forEach((el) => {
			el.value.forEach(opt => {
				bundleOptions.push(btoa(`bundle/${el.id}/${opt}/${el.quantity}`))
			})
		});

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
			bundleOptions,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Product Price Formatter */
	const _priceFormatter = (priceValue, priceType) => {
		let price = '';

		switch (priceType) {
			case 'USD':
				price = `$${priceValue}`;
				break;
			case 'GBP':
				price = `&#163;${priceValue}`;
				break;
			case 'EUR':
				price = `&#8364;${priceValue}`;
				break;
			case 'PLN':
				price = `${priceValue} &#122;&#322;`;
				break;
			case 'ZAR':
				price = `R ${priceValue}`;
				break;
			default:
				price = priceValue;
		}

		if (price) {
			return price.replace(".", ",");
		}
	};

	/* Get Cart */
	const _getCart = async (cartId) => {
		// Catch no cartId.
		if (!cartId) {
			return Promise.resolve('Need CartId.');
		}

		const requestBody = `query getCartDetails($cartId: String!) {
			cart(cart_id: $cartId) {
				email
				cart_expiry {
					updated_at
					expires_at
				}
				billing_address {
					city
					country {
						code
						label
					}
					firstname
					lastname
					postcode
					region {
						code
						label
					}
					street
					telephone
				}
				items {
					id
					product {
						${customProductPropertiesInterface}
						${productPropertiesInterface}
						... on ConfigurableProduct {
							variants {
								product {
									${productPropertiesInterface}
									${customProductPropertiesInterface}
								}
								attributes {
									label
									code
									uid
								}
							}
						}
						...on SimpleProduct {
							parent_sku
						}
					}
					... on ConfigurableCartItem {
						configurable_options {
							id
							option_label
							configurable_product_option_uid
							value_label
							value_id
							configurable_product_option_value_uid
						}
					}
					... on BundleCartItem {
						bundle_options {
							id
							label
							type
							values {
								id
								label
								price
								quantity
							}
						}
					}
					quantity
					prices {
						row_total_including_tax {
							value
							currency
						}
						personalisation_price {
							currency
							value
						}
						personalisation_tax {
							currency
							value
						}
					}
				}
				applied_coupons {
					code
				}
				prices {
					grand_total {
						value
						currency
					}
					personalisation_price {
						currency
						value
					}
					personalisation_tax {
						currency
						value
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query).then(async (response) => {
			if (response.data.cart.items) {
				response.data.cart.items = response.data.cart.items.filter(el => el !== null && el !== undefined);
				for (const item of response.data.cart.items) {
					const product = item.product;
					if (product && product.__typename === 'ConfigurableProduct') {
						for (const variant of product.variants) {
							variant.product.parent_url_key = product.url_key;
						}
					} else if (product && product.parent_sku) {
						const secondRequest = await _getProductUrlKey(product.parent_sku);
						if (secondRequest.data.products.items.length > 0) {
							const parentProduct = secondRequest.data.products.items[0];
							product.parent_url_key = parentProduct.url_key;
						}
					}
				}
			}
			// Add info on user action for messagebar
			response.lastItemAdded = lastItemAdded;
			response.lastActionTaken = lastActionTaken;
			response.cartMessage = cartMessage;

			Utils.setLocalStorage('commerce-cart-storage', response);

			// Trigger cart update window event
			window.dispatchEvent(cartUpdate);

			return response;
		});

		return request;
	};

	/* Set Cart Cookie */
	const _setCommerceCookie = (key, value, min) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[2]}/${pathArray[3]}`;
		}

		if (window.location.hostname.indexOf('localhost') > -1) {
			storeID = '/';
		}

		const expires = new Date();
		expires.setTime(expires.getTime() + min * 60 * 1000);
		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=${value};${isSecure ? 'secure;' : ''
			}samesite=strict;expires=${expires.toUTCString()};path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Erase Cart Cookie */
	const _eraseCommerceCookie = (key) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[2]}/${pathArray[3]}`;
		}

		if (window.location.hostname.indexOf('localhost') > -1) {
			storeID = '/';
		}

		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=;${isSecure ? 'secure;' : ''
			}samesite=strict;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Create Empty Cart */
	const _createEmptyCart = async () => {
		const authToken = Utils.getCookie('commerce-auth-token');
		const requestBody = authToken
			? '{customerCart{id}}'
			: 'mutation {createEmptyCart}';

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			const cartId = authToken
				? response.data.customerCart.id
				: response.data.createEmptyCart;

			_setCommerceCookie('commerce-cart-id', cartId, 20);
			_getCart(cartId);
			return cartId;
		});

		return request;
	};

	/* Set Auth Token */
	const _setAuthToken = async (email, password) => {
		const requestBody = `mutation generateCustomerTokenSession($email: String!, $password: String!) {
			generateCustomerTokenSession(email: $email, password: $password) {
			  token
			}
		}`;

		const variablesObj = {
			email,
			password
		};

		const query = JSON.stringify({ query: requestBody, variables: variablesObj });

		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.generateCustomerTokenSession.token;
				_setCommerceCookie('commerce-auth-token', authToken, 1440);
				sessionStorage.removeItem('customerData');
				localStorage.removeItem('loyaltyInfo');
				localStorage.removeItem('missionList');
				if (window.location.href.indexOf('/app/') > -1 && window.localforage) {
					window.localforage.setItem('loyalty', {email, key: password});
				}
				return { authToken };
			}
			if (window.location.href.indexOf('/app/') > -1 && window.localforage) {
				window.localforage.removeItem('loyalty');
			}
			return { errors };
		});

		return request;
	};

	const _changeCustomerPassword = async (currentPassword, newPassword) => {
		const requestBody = `mutation {
				changeCustomerPassword(
				  currentPassword: "${currentPassword}"
				  newPassword: "${newPassword}"
				) {
				  email
				}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log("Request",request)
		
		return request;
	};

	const _resetPassword = async (resetPasswordToken, newPassword) => {
		const requestBody = `mutation {
			resetPassword(
			  email: "",
			  resetPasswordToken: "${resetPasswordToken}",
			  newPassword: "${newPassword}"
			)
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log(request);
		
		return request;
	};

	const _requestPasswordResetEmail = async (email, accessPoint) => {
		const requestBody = `mutation {
			requestPasswordResetEmail(
			  email: "${email}"
			  ${accessPoint ? `accessPoint: "${accessPoint}"` : ''}
			)
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log(request);
		
		return request;
	};

	const _deleteCustomer = async (email) => {
		const requestBody = `mutation {
			deleteCustomer (
			  email: "${email}"
			) {
			  result
			}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		console.log(request);
		
		return request;
	};


	const _validateEmailAndPhone = async (email, phone) => {
		const requestBody = ` mutation{
				validateEmailAndPhone(
					email:"${email}"
					phone:"${phone}"
					){
					emailUsed
					phoneUsed
					}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log("Request",request)
		
		return request;
	};

	const _validateDeviceData = async (deviceId) => {
		const requestBody = ` mutation{
				validateDeviceData(
					deviceId:"${deviceId}"
					){
					is_valid
					message
					}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		console.log("Request",request)
		
		return request;
	};

	const _customerRegistrationV2Submit3 = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('Need Customer Object.');
		}

		const requestBody = `
			mutation customerRegistrationV2(
				$firstname: String!	
				$lastname: String!	
				$email: String!	
				$mobile_number: String!	
				$date_of_birth: String!	
				$gender: String!	
				$consent_store_my_data: Boolean!
				$consent_email_marketing: Boolean!
				$consent_mobile: Boolean!
				$consent_profiling: Boolean!
				$age_verified: Boolean!
				$email_verified: Boolean!
				$brand: String!
				$source: String!
				$mostly_used_products: String!
				$cigarette_sticks_usage_count: String!
				$reason_on_buyglo: String!
				$reason_on_buyglo_other: String!
				$region_of_origin: String
           		$first_glo_device: String
			){
				customerRegistrationV2(
					input: {
						firstname:$firstname
						lastname:$lastname
						email:$email
						mobile_number: $mobile_number
						date_of_birth:$date_of_birth
						gender:$gender
						consent_store_my_data:$consent_store_my_data
						consent_email_marketing:$consent_email_marketing
						consent_mobile:$consent_mobile
						consent_profiling:$consent_profiling
						age_verified:$age_verified
						email_verified:$email_verified
						brand:$brand
						source:$source
						mostly_used_products: $mostly_used_products
						cigarette_sticks_usage_count: $cigarette_sticks_usage_count
						reason_on_buyglo: $reason_on_buyglo
						reason_on_buyglo_other: $reason_on_buyglo_other
						region_of_origin: $region_of_origin
      					first_glo_device: $first_glo_device
						assets : {
							name: "${customerObject.assets.name}",
							purchasedate: "${customerObject.assets.purchasedate}",
							status: "${customerObject.assets.status}",
							brand: "${customerObject.assets.brand}",
							colour: "${customerObject.assets.colour}",
							model: "${customerObject.assets.model}",
							type: "${customerObject.assets.type}",
							assetsource: "${customerObject.assets.assetsource}",
							couponcode: "${customerObject.assets.couponcode}",
						}
					}
				) {
					status
					message
					sf_id
					asset_id
					device_error
				}
			}
		
		`;
		const variablesObj = {
			firstname: customerObject.firstname,
			lastname: customerObject.lastname,
			email: customerObject.email,
			date_of_birth: customerObject.date_of_birth,
			gender: customerObject.gender,
			mobile_number: customerObject.mobile_number,
			age_verified : customerObject.age_verified,
			email_verified : customerObject.email_verified,
			brand : customerObject.brand,
			source : customerObject.source,
			consent_mobile: customerObject.consent_mobile,
			consent_email_marketing: customerObject.consent_email_marketing,
			consent_profiling: customerObject.consent_profiling,
			consent_store_my_data: customerObject.consent_store_my_data,
			mostly_used_products: customerObject.mostly_used_products,
			cigarette_sticks_usage_count: customerObject.cigarette_sticks_usage_count,
			reason_on_buyglo: customerObject.reason_on_buyglo,
			reason_on_buyglo_other: customerObject.reason_on_buyglo_other,
		};
		if(customerObject.region_of_origin && customerObject.first_glo_device){
			variablesObj.region_of_origin = customerObject.region_of_origin;
			variablesObj.first_glo_device = customerObject.first_glo_device;
		}

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');

		return request;
	};

	const _updateCustomerV2 = async(customerObject) => {
		if (!customerObject) {
			return Promise.resolve('Need Customer Object.');
		}

		const requestBody = `
			mutation customerUpdateV2(
				$firstname: String!	
				$lastname: String!	
				$email: String!	
				$sf_id: String!
				$mobile_number: String!	
				$date_of_birth: String!	
				$gender: String!	
				$consent_store_my_data: Boolean!
				$consent_email_marketing: Boolean!
				$consent_mobile: Boolean!
				$consent_profiling: Boolean!
				$age_verified: Boolean!
				$email_verified: Boolean!
				$brand: String!
				$source: String!
				$mostly_used_products: String!
				$cigarette_sticks_usage_count: String!
				$reason_on_buyglo: String!
				$reason_on_buyglo_other: String!
			){
				customerUpdateV2(
					input: {
						firstname:$firstname
						lastname:$lastname
						email:$email
						sf_id: $sf_id
						mobile_number: $mobile_number
						date_of_birth:$date_of_birth
						gender:$gender
						consent_store_my_data:$consent_store_my_data
						consent_email_marketing:$consent_email_marketing
						consent_mobile:$consent_mobile
						consent_profiling:$consent_profiling
						age_verified:$age_verified
						email_verified:$email_verified
						brand:$brand
						source:$source
						mostly_used_products: $mostly_used_products
						cigarette_sticks_usage_count: $cigarette_sticks_usage_count
						reason_on_buyglo: $reason_on_buyglo
						reason_on_buyglo_other: $reason_on_buyglo_other
						assets : {
							name: "${customerObject.assets.name}",
							purchasedate: "${customerObject.assets.purchasedate}",
							status: "${customerObject.assets.status}",
							brand: "${customerObject.assets.brand}",
							colour: "${customerObject.assets.colour}",
							model: "${customerObject.assets.model}",
							type: "${customerObject.assets.type}",
							assetsource: "${customerObject.assets.assetsource}",
							couponcode: "${customerObject.assets.couponcode}",
						}
					}
				) {
					status
					message
					sf_id
					asset_id
					device_error
				}
			}
		
		`;
		const variablesObj = {
			firstname: customerObject.firstname,
			lastname: customerObject.lastname,
			email: customerObject.email,
			sf_id: customerObject.sf_id,
			date_of_birth: customerObject.date_of_birth,
			gender: customerObject.gender,
			mobile_number: customerObject.mobile_number,
			age_verified : customerObject.age_verified,
			email_verified : customerObject.email_verified,
			brand : customerObject.brand,
			source : customerObject.source,
			consent_mobile: customerObject.consent_mobile,
			consent_email_marketing: customerObject.consent_email_marketing,
			consent_profiling: customerObject.consent_profiling,
			consent_store_my_data: customerObject.consent_store_my_data,
			mostly_used_products: customerObject.mostly_used_products,
			cigarette_sticks_usage_count: customerObject.cigarette_sticks_usage_count,
			reason_on_buyglo: customerObject.reason_on_buyglo,
			reason_on_buyglo_other: customerObject.reason_on_buyglo_other,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');

		return request;
	}

	const _customerRegistrationV2Submit4 = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('Need Customer Object.');
		}

		const requestBody = `
			mutation customerRegistrationV2(
				$firstname: String!	
				$lastname: String!	
				$email: String!	
				$mobile_number: String!	
				$date_of_birth: String!	
				$gender: String!	
				$codice_fiscale: String!
				$password: String!	
				$loyalty_optin: String!
				$consent_store_my_data: Boolean!
				$consent_email_marketing: Boolean!
				$consent_mobile: Boolean!
				$consent_profiling: Boolean!
				$age_verified: Boolean!
				$email_verified: Boolean!
				$brand: String!
				$source: String!
				$mostly_used_products: String!
				$cigarette_sticks_usage_count: String!
				$reason_on_buyglo: String!
				$reason_on_buyglo_other: String!
				$region_of_origin: String
           		$first_glo_device: String
				$asset_id: String!
				$sf_id: String!
			){
				customerRegistrationV2(
					input: {
						firstname:$firstname
						lastname:$lastname
						email:$email
						mobile_number: $mobile_number
						date_of_birth:$date_of_birth
						gender:$gender
						codice_fiscale: $codice_fiscale
						password: $password	
						loyalty_optin: $loyalty_optin
						consent_store_my_data:$consent_store_my_data
						consent_email_marketing:$consent_email_marketing
						consent_mobile:$consent_mobile
						consent_profiling:$consent_profiling
						age_verified:$age_verified
						email_verified:$email_verified
						brand:$brand
						asset_id:$asset_id
						sf_id:$sf_id
						source:$source
						mostly_used_products: $mostly_used_products
						cigarette_sticks_usage_count: $cigarette_sticks_usage_count
						reason_on_buyglo: $reason_on_buyglo
						reason_on_buyglo_other: $reason_on_buyglo_other
						region_of_origin: $region_of_origin
      					first_glo_device: $first_glo_device
						assets : {
							name: "${customerObject.assets.name}",
							purchasedate: "${customerObject.assets.purchasedate}",
							status: "${customerObject.assets.status}",
							brand: "${customerObject.assets.brand}",
							colour: "${customerObject.assets.colour}",
							model: "${customerObject.assets.model}",
							type: "${customerObject.assets.type}",
							assetsource: "${customerObject.assets.assetsource}",
							couponcode: "${customerObject.assets.couponcode}",
						}
					}
				) {
					status
					message
				}
			}
		
		`;
		const variablesObj = {
			firstname: customerObject.firstname,
			lastname: customerObject.lastname,
			email: customerObject.email,
			date_of_birth: customerObject.date_of_birth,
			gender: customerObject.gender,
			password: customerObject.password,
			loyalty_optin: customerObject.loyalty_optin,
			codice_fiscale:customerObject.codice_fiscale,
			mobile_number: customerObject.mobile_number,
			age_verified : customerObject.age_verified,
			email_verified : customerObject.email_verified,
			brand : customerObject.brand,
			source : customerObject.source,
			consent_mobile: customerObject.consent_mobile,
			consent_email_marketing: customerObject.consent_email_marketing,
			consent_profiling: customerObject.consent_profiling,
			consent_store_my_data: customerObject.consent_store_my_data,
			mostly_used_products: customerObject.mostly_used_products,
			cigarette_sticks_usage_count: customerObject.cigarette_sticks_usage_count,
			reason_on_buyglo: customerObject.reason_on_buyglo,
			reason_on_buyglo_other: customerObject.reason_on_buyglo_other,
			sf_id: customerObject.sf_id,
			asset_id: customerObject.asset_id
		};

		if (customerObject.first_glo_device && customerObject.region_of_origin) {
			variablesObj.region_of_origin = customerObject.region_of_origin;
			variablesObj.first_glo_device = customerObject.first_glo_device;
		}

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');

		return request;
	};

	
	const _orderHistory = async () => {
		const requestBody = `{
			customer {
				orders{
				  total_count
				  items {
							id
							order_date
							number
							shipping_method
							status
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
							}
							items{
								product_name
								product_sku
								quantity_ordered
								product_type
								product_sale_price{
									currency
									value
								}
								selected_options{
									label
									value
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
							
				  }
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};

	
	const _getOrdersOfPage = async (currentPage) => {
		const requestBody = `{
			customer {
				orders(currentPage: ${currentPage}, pageSize: 5) {
				  items {
							id
							order_date
							number
							shipping_method
							status
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
							}
							items{
								product_name
								product_sku
								quantity_ordered
								product_type
								product_sale_price{
									currency
									value
								}
								selected_options{
									label
									value
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
							
				  }
				}
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};


	const _getOrderInfo = async (order_number) => {
		const requestBody = `{
			customer {
				orders(
						filter: { 
							number: { eq: "${order_number}" } 
						}){
				  items {
							id
							order_date
							number
							invoices{
								id
								number
								items{
									product_name
								}
							}
							shipments{
								id
								number
								items{
									product_name
									product_sku
									quantity_shipped
								}
								tracking {
									tracking_url
								}
							}
							shipping_method
							status
							glovo_tracking_url
							total{
								total_tax{
									value
								}
								base_grand_total{
									value
									currency
								}
								subtotal {
									value
									currency
								}
								discounts{
									amount{
										value
									}
								}
								shipping_handling{
									amount_including_tax{
										value
									}                 
								}
							}
							
							insider_club {
								value
								currency
							}
					
							items{
								product_name
								product_sku
								quantity_ordered
								personalisation {
									front_font
									front_text
									front_orientation
									back_text
									back_font
									back_orientation
									icon
									pattern
								}
								
								product_sale_price{
									currency
									value
								}
								product_price_incl_tax {
									currency
									value
								}
								selected_options{
									label
									value
								}
								__typename
								... on BundleOrderItem {
									bundle_options {
										values {
											quantity
											product_sku
										}
									}
								}
							}
							payment_methods{
								name
								type
								__typename
								additional_data{
									name
									value
									__typename
								}
							}
							
							billing_address{
								firstname
								lastname
								street
								city
								region
								postcode
								country_code
								telephone
							}
							shipping_address{
								firstname
								lastname
								street
								city
								region		
								postcode
								country_code
								telephone
							}
				  }
				}
			  }
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		return request;
	};




	/* Merge Carts */
	const _mergeCarts = async (currentCartId, responseCartId) => {
		const requestBody = `mutation {
			mergeCarts(
			  source_cart_id: "${currentCartId}",
			  destination_cart_id: "${responseCartId}"
			) {
			  items {
				id
				product {
				  name
				  sku
				}
				quantity
			  }
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			if (response) {
				_getCart(responseCartId);
			}

			return response;
		});

		return request;
	};

	/* Check Login */
	const _checkLogin = async () => {

		let appSplash = '';
		if (window.location.href.indexOf('/app/') > -1) {
			appSplash = $('.bat-signin-gloit-mobile-splash');
			if (appSplash.length > 0) {
				appSplash.removeClass('uk-hidden');
			}
		}

		const requestBody = `{
			me {
				is_logged_in
				active_cart_id
			}
	  	}`;

		const query = JSON.stringify({ query: requestBody });

		const isLoggedIn = await _makePostRequest(query).then((response) => {
			const responseCartId = response.data.me.active_cart_id;
			const isLoggedIn = response.data.me.is_logged_in;

			Utils.setCookie('commerce-auth-verify', isLoggedIn);
			Utils.setCookie('mage-cache-sessid', isLoggedIn);

			if (responseCartId && window.location.href.indexOf('/logout') < 0) {
				_setCommerceCookie('commerce-cart-id', responseCartId, 20);
				_getCart(responseCartId);
				window.dispatchEvent(loginAction);
				return true;
			} else {
				if (window.location.href.indexOf('/app/') > -1 && window.localforage) {
					return window.localforage.getItem('loyalty').then(loginData => {
						if (loginData && loginData.social_id) {
							return _socialLogin(loginData).then(res => {
								_checkLogin();
							});
						} else if (loginData && loginData.key) {
							return _setAuthToken(loginData.email, loginData.key).then(res => {
								_checkLogin();
							});
						} else {
							_eraseCommerceCookie('commerce-cart-id');
							localStorage.removeItem('commerce-cart-storage');
							sessionStorage.removeItem('customerData');
							window.dispatchEvent(loginAction);
							appSplash.addClass('uk-hidden');
							return false;
						}
					});
				} else {
					_eraseCommerceCookie('commerce-cart-id');
					localStorage.removeItem('commerce-cart-storage');
					sessionStorage.removeItem('customerData');
					window.dispatchEvent(loginAction);
					return false;
				}
			}
		});

		return isLoggedIn;
	};

	/* Login Customer */
	const _loginCustomer = async (sourceCartId, destCartId) => {

		if (Utils.getCookie('commerce-auth-verify') === 'true') {
			const authToken = Utils.getCookie('commerce-auth-token');

			if (authToken && sourceCartId && (sourceCartId !== destCartId)) {
				const loginCustomerOk = await _mergeCarts(sourceCartId, destCartId).then((response) => {
					if (response) {
						if (response.errors) {
							return false;
						}
						else {
							return true;
						}
					}
					else {
						return false;
					}

				});
				return loginCustomerOk;
			}
			else {
				if (!sourceCartId) {
					return true;
				}
				return false;
			}
		}
		else {
			return false;
		}
	};

	/* Logout Customer */
	const _logoutCustomer = () => {
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			console.log("Rimozione cookie associati al customer con token: " + authToken);
			_eraseCommerceCookie('commerce-cart-id');
			_eraseCommerceCookie('commerce-auth-token');
			sessionStorage.removeItem('customerData');
			localStorage.removeItem('loyaltyInfo');
			localStorage.removeItem('missionList');
			Utils.removeLocalStorage('commerce-cart-storage');
			Utils.removeLocalStorage('mage-cache-timeout');
			Utils.removeLocalStorage('mage-cache-storage');
			window.dispatchEvent(cartUpdate);
			return true;
		}

		return true;

	};

	const _revokeCustomerToken = async () => {

		if (window.localforage) {
			const loginData = await window.localforage.getItem('loyalty');
			if (loginData && loginData.social_id) {
				await _socialLogin(loginData);
			} else if (loginData && loginData.key) {
				await _setAuthToken(loginData.email, loginData.key);
			}
		}
		
		const requestBody = `
			mutation {
				revokeCustomerTokenSession {
					result
				}
			}		
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		window.localforage.removeItem('loyalty');

		return request;
	}

	/* Remove Item From Cart */
	const _removeItemFromCart = async (cartItemId) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId or no cartItemId.
		if (!cartId || !cartItemId) {
			return Promise.resolve('Need cartId and cartItemId.');
		}

		const requestBody = `mutation removeItemFromCart($cartId: String!, $cartItemId: Int!) {
			removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		/* eslint-disable-next-line no-console */
		console.log('removeItemFromCart', request);

		// Add cart message if in response
		cartMessage = request.message ? request.message : '';

		// Update local storage
		lastActionTaken = 'remove';
		_getCart(cartId);

		return request;
	};

	/* Update Item From Cart */
	const _updateItemInCart = async (cartItemId, quantity) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId, cartItemId and cartItemQuantity.
		if (!cartId || !cartItemId || !quantity) {
			return Promise.resolve('Need cartId, cartItemId and quantity.');
		}

		const requestBody = `mutation updateCartItem(
			$cartId: String!
			$cartItemId: Int!
			$quantity: Float!
		) {
			updateCartItems(
				input: {
					cart_id: $cartId
					cart_items: [{ cart_item_id: $cartItemId, quantity: $quantity }]
				}
			) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
			quantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		// Add cart message if in response
		cartMessage = request.message ? request.message : '';

		// Update local storage
		lastActionTaken = 'update';
		_getCart(cartId);

		return request;
	};

	const _clearCartAndAddItem = async (productObject) => {

		const localCart = JSON.parse(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		const cartObj = localCart.data.cart;
		if (cartObj.items && cartObj.items.length > 0) {
			for (const item of cartObj.items) {
				await _removeItemFromCart(item.id);
			}
		}

		return _addItemToCart(productObject);
	}

	/* Add Item To Cart Analytics */
	const _dispatchAddItemToCartEvent = (productObject) => {
		const eventData = {
			event: 'addToCart',
			ecommerce: {
				currencyCode: '',
				add: {
					products: [
						{
							name: productObject.productName,
							id: productObject.productSku,
							price: '',
							brand: 'Glo',
							category: '',
							// variant: 'Grey',
							quantity: productObject.productQuantity,
							format: 'Accessory',
							// flavour: 'apple',
							// nicotine: '6ml',
							systemType: '',
						},
					],
				},
			},
		};

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push(eventData);
		}

		// const addItemToCartEvent = new CustomEvent('addItemToCart', eventData);

		// document.dispatchEvent(addItemToCartEvent);
	};

	/* Add Item To Cart */
	const _addItemToCart = async (productObject) => {
		let cartId = Utils.getCookie('commerce-cart-id');
		let request = '';
		const cartObject = productObject;
		const isWalletProduct = productObject.is_wallet_product == '1';

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// Set cart item Name for message bar
		lastActionTaken = 'add';
		lastItemAdded = cartObject.productName;
		// Utils.setCookie('commerce-item-message', cartObject.productName);

		// If no cartId, create one.
		if (!cartId) {
			cartId = await _createEmptyCart();
		}

		const isLoggedIn = Utils.getCookie('commerce-auth-verify') === 'true';

		cartObject.cartId = cartId;

		/* eslint-disable-next-line no-console */
		console.log('addItemToCart cartId', cartObject);

		switch (cartObject.productType) {
			case 'SimpleProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				if (isWalletProduct) {
					request = await _addVirtualProduct(cartObject);
				} else {
					request = await _addSimpleProduct(cartObject);
				}
				break;
			case 'ConfigurableProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addConfigurableProduct(cartObject);
				break;
			case 'BundleProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addBundleProduct(cartObject);
				break;
			case 'VirtualProduct':
				if (isLoggedIn) {
					const walletCheck = await _walletCartCheck();
					if (walletCheck && walletCheck.data && walletCheck.data.WalletCartCheck) {
						if ((isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_normal)
							|| (!isWalletProduct && walletCheck.data.WalletCartCheck.is_cart_wallet_only)) {
							Utils.setLocalStorage('commerce-product-pending', productObject);
							window.dispatchEvent(mixedCart);
							break;
						}
					}
				}
				request = await _addVirtualProduct(cartObject);
				break;
			default:
		}

		if (request) {
			// Add cart message or error message if in response
			/* eslint-disable-next-line no-nested-ternary */
			cartMessage = request.message
				? request.message
				: request.errors
					? request.errors[0].message
					: '';

			if (request.data && request.data.addProductsToCart && request.data.addProductsToCart.user_errors && request.data.addProductsToCart.user_errors.length) {
				cartMessage = request.data.addProductsToCart.user_errors[0].message;
			} 

			const cart = await _getCart(cartId);

			_dispatchAddItemToCartEvent(productObject);

			return cart;
		}
		return null;
	};

	/* Get Cart Total Quantity */
	const _getCartQuantity = () => {
		const localCart = $.parseJSON(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		let cartQuantity = 0;

		if (
			localCart &&
			localCart.data &&
			localCart.data.cart &&
			localCart.data.cart.items.length > 0
		) {
			cartQuantity = localCart.data.cart.items
				.map((item) => {
					return item.quantity;
				})
				.reduce((prev, next) => {
					return prev + next;
				});
		}

		return cartQuantity;
	};

	const _getCustomerDetails = async () => {

		const customerData = _getSessionStorage('customerData');
		let request = '';

		if(!customerData || customerData === 'undefined' || customerData.indexOf('customer_id') < 0) {

			const requestBody = `{
				customer {
					customer_id
					global_loyalty_optin
					codice_fiscale
					firstname
					lastname
					prefix
					email
					gender
					date_of_birth
					is_subscribed
					is_age_verified
					is_approved
					consent_email_marketing
					consent_mobile
					consent_profiling
					salesforce_customer_id
					created_at				  
					addresses {
						id
						firstname
						lastname
						street
						city
						region {
							region_code
							region
						}
						postcode
						country_code
						default_billing
						default_shipping
						telephone
					}
					linked_accounts {
						created_at
						entity_id
						provider_type
						social_id
					}
				}
			}`;

			const query = JSON.stringify({ query: requestBody });

			request = await _makePostRequest(query);
			_setSessionStorage('customerData', request);
			
		} else {
			request = JSON.parse(customerData);
		}

		if (window.location.href.indexOf('/myaccount') > -1 && 
				!window.syncSent &&
				request &&
				request.data &&
				request.data.customer &&
				request.data.customer.global_loyalty_optin === '0') {
			window.syncSent = true;
			const syncDevices = await _syncOfflineDevices();
			if (syncDevices && 
					syncDevices.data && 
					syncDevices.data.syncOfflineDevices && 
					syncDevices.data.syncOfflineDevices.status !== 0) {
				sessionStorage.removeItem('customerData');
				request.data.customer.global_loyalty_optin = '' + syncDevices.data.syncOfflineDevices.status;
				_setSessionStorage('customerData', request);
			}
		}

		if (request &&
				request.data &&
				request.data.customer &&
				!request.data.customer.salesforce_customer_id) {
			const update = await _updateSalesforceId();
			if (update && 
					update.data && 
					update.data.updateSalesforceId) {
				sessionStorage.removeItem('customerData');
				request.data.customer.salesforce_customer_id = update.data.updateSalesforceId.salesforce_customer_id;
				_setSessionStorage('customerData', request);
			}
		}

		return request;
	};

	// Get Product Reviews
	const _getProductReviews = async (productSku) => {
		// Catch no productId.
		if (!productSku) {
			return Promise.resolve('Need Product Sku');
		}

		const requestBody = `{
            products(filter: { sku: {eq: "${productSku}"} }) {
				items {
					name
					sku
					rating_summary
					review_count
					reviews(pageSize: 100) {
						items {
							average_rating
							nickname
							loyalty_user_review
							summary
							text
							ratings_breakdown {
								name
								value
							}
							created_at
						}
					}
				}
				total_count
				page_info {
				  	page_size
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _getReviewsMetadata = async () => {
		const requestBody = `{
            productReviewRatingsMetadata {
				items {
					id
					name
					values {
						value
						value_id
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _addReview = async (reviewObject) => {
		// Catch no productId.
		if (!reviewObject) {
			return Promise.resolve('Need Review Object.');
		}

		const requestBody = `mutation createProductReview(
			$sku: String!
			$nickname: String!
			$summary: String!
			$text: String!
			$ratingId: String!
			$ratingValueId: String!
		) {
			createProductReview(input: {
				sku: $sku
				nickname: $nickname
				summary: $summary
				text: $text
				ratings: [{
					id: $ratingId
					value_id: $ratingValueId
				}]
			}) {
				review {
					product {
						sku
					}
					summary
					text
					nickname
					created_at
					average_rating
					ratings_breakdown {
						name
						value
					}
				}
			}
		}`;

		const variablesObj = {
			sku: reviewObject.sku,
			nickname: reviewObject.nickname,
			summary: reviewObject.summary,
			text: reviewObject.text,
			ratingId: reviewObject.ratingId,
			ratingValueId: reviewObject.ratingValueId
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	};

	const _getPsnOptions = async () => {
		const requestBody = `{
			psnOptions {
				fonts {
					font
					font_name
					font_size
					preview_text
				}
				patterns {
					pattern_name
					pattern_image
					thumbnail_image
				}
				icons {
					icon_name
					icon_image
					thumbnail_image
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _getPsnMetadata = async () => {
		const requestBody = `{
			moduleConfig {
			 	personalisation {
					enabled
					configuration {
				  		max_characters_horizontal
				  		max_characters_vertical
				  		regex
				  		price
				  		special_price
				  		disclaimer
				  		restricted_words {
							word
				  		}
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}



	const _qrNewsletterCharging = async (form) => {

		const requestBody = `mutation {
			QRNewsletter (
				email_address: "${form.email}"
				mobile_number:"${form.phone}"
				brand: "GLO"
				first_name:  "${form.firstname}"
				last_name: "${form.lastname}"
				store_my_data: ${form.store_my_data}
				age_verified: ${form.age_verified}
				emailVerified: ${form.emailVerified}
				marketing_by_email:  ${form.marketing_by_email}
				marketing_by_sms: ${form.marketing_by_sms}
				source: "${form.source}"
				record_type: "Newsletter Subscriber"
				consent_profiling: ${form.consent_profiling}
				dateofBirth: "${form.date_of_birth}"
			)
			{
					status
					message
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	}

	const _guestSubscribeNewsletter = async (form) => {

		const requestBody = `mutation {
			guestSubscribeNewsletterMarketing (
				input: {
					email: "${form.email}"
					firstname: "${form.firstname}"
					lastname: "${form.lastname}"
					phone: "${form.phone}"
					marketing_by_email: ${form.marketing_by_email}
					marketing_by_sms: ${form.marketing_by_sms}
					date_of_birth: "${form.date_of_birth}"
					${form.consent_profiling !== null ? `consent_profiling: ${form.consent_profiling}` : '' }
					store_my_data: ${form.store_my_data}
				}
			)
			{
			  	status
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	}

	const _walletCartCheck = async () => {
		const requestBody = `{
			WalletCartCheck {
				is_cart_normal
				is_mixed_cart
				is_cart_wallet_only
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	}

	/* Get Product Alerts */
	const _getProductAlerts = async () => {
		const requestBody = `{
			productAlert {
				product_id
				status
			}
		}`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Product Alert */
	const _addProductAlert = async (productId) => {
		// Catch no productId.
		if (!productId) {
			return Promise.resolve('Need productId.');
		}

		const requestBody = `mutation productAlertCustomerNotifyInStock(
			$productId: Int!
		) {
			productAlertCustomerNotifyInStock(
				input: {
					productId: $productId
				}
			) {
				product_id
				status
			}
		}`;

		const variablesObj = {
			productId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	const _getLoyaltyRestrictedSkus = async () => {
		const requestBody = `{
			moduleConfig {
				loyalty_annex_cloud {
					enabled
					configuration {
						dashboard_iframe_url
						restricted_skus {
							sku
						}
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		let restrictedArray = request.data.moduleConfig.loyalty_annex_cloud.configuration.restricted_skus;
		if (restrictedArray && restrictedArray.length > 0) {
			restrictedArray = restrictedArray.map((el) => el.sku);
		}
		return restrictedArray;
	}

	const _getLivechatConfig = async () => {

		const livechatConfig = localStorage.getItem('livechatConfig');

		if(livechatConfig) {
			return JSON.parse(livechatConfig);
		}

		const requestBody = `{
			moduleConfig {
				unyco_chat {
					enabled
					configuration {
						url
						eu_proactive_url
						container_code
						container_id
					}
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		localStorage.setItem('livechatConfig', JSON.stringify(request));
		return request;
	}

	const _deviceRegistration = async () => {
		//TODO registrazione dispositivo
		const requestBody = `query {
			moduleConfig {
			  device_registration {
				enabled
				configuration {
				  has_device_types
				  device_types
				  has_device_sources
				  device_sources
				  has_cms_block_login
				  cms_block_id_login
				  has_cms_block_device_registration
				  cms_block_id_device_registration
				  reward_free_skus_enabled
				  reward_free_skus
				  reward_buy_skus
				}
			  }
			}
		  }`;

		const request = await _makePostRequest(JSON.stringify({query: requestBody}));
		return request;
	}

	const _registeredDevicesList = async () => {
		//TODO registrazione dispositivo
		const requestBody = `query{
			RegisteredDeviceList{
			  customer_id
			  device_id
			  device_type
			  device_colour
			  purchase_location
			  purchase_town
			  purchase_date
			  created_at
			}
		  }`;

		const request = await _makePostRequest(JSON.stringify({query: requestBody}));
		return request;
	}

	const _registerDevice = async (device_id,device_type, device_colour,device_source) => {
		const requestBody = `mutation {
			registerDevice(
				input: {
					device_id: "${device_id}"
					additional_data: {
						device_type: "${device_type}"
						device_colour: "${device_colour}"
						device_source: "${device_source}"
					}
				}
			) {
				status
				message
			}
		}`;

		const request = await _makePostRequest(JSON.stringify({query: requestBody}));
		sessionStorage.removeItem('customerData');
		if(request && request.data && request.data.registerDevice && request.data.registerDevice.status){
			const { pathname } = new URL(window.location.href);
			const pagename = pathname.split('/').pop();
			const userData = await _getCustomerDetails();
			
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					UserID: userData.data.customer.customer_id,
					DeviceId: device_id,
					PageType: pagename,
					Country: 'IT',
					SiteSection: pagename,
					event: 'deviceRegistration'
				});		
			}
			return true;
		}else{
			return false;
		}
	}

	const _createCustomerV2 = async (customerObject, fromMobile) => {
		if (!customerObject) {
			return Promise.resolve('Need Customer Object.');
		}

		const requestBody = `
			mutation createCustomerV2(
				$firstname: String!	
				$lastname: String!	
				$dob: String!	
				$gender: Int!	
				$mgm_promo_code: String!
				$codice_fiscale: String!	
				$email: String!	
				$password: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!	
				$region_code: String!	
				$country_code: CountryCodeEnum
				$consent_email_marketing: String!
				$consent_mobile: String!
				$consent_profiling: String!
				$consent_store_my_data: String!
				$mostly_used_products: String!
				$cigarette_sticks_usage_count: String!
				$reason_on_buyglo: String!
				$reason_on_buyglo_other: String!
				$region_of_origin: String
           		$first_glo_device: String
			){
				createCustomerV2(
					input: {
						firstname: $firstname
						lastname: $lastname
						email: $email
						password: $password
						dob: $dob
						codice_fiscale: $codice_fiscale
						gender: $gender
						mgm_promo_code: $mgm_promo_code
						address: {
							street: $street
							telephone: $telephone
							postcode: $postcode
							city: $city
							region: {
								region_code: $region_code,
								region: $region
							}
							country_code: $country_code
							firstname: $firstname
							lastname: $lastname
							default_shipping: true
							default_billing: true
						}
						consent_email_marketing: $consent_email_marketing
						consent_mobile: $consent_mobile
						consent_profiling: $consent_profiling
						consent_store_my_data: $consent_store_my_data
						mostly_used_products: $mostly_used_products
						cigarette_sticks_usage_count: $cigarette_sticks_usage_count
						reason_on_buyglo: $reason_on_buyglo
						reason_on_buyglo_other: $reason_on_buyglo_other
						region_of_origin: $region_of_origin
      					first_glo_device: $first_glo_device
						${customerObject.linked_account && customerObject.linked_account.social_id && customerObject.linked_account.provider_type ? `
							linked_account: {
								social_id: "${customerObject.linked_account.social_id}"
        						provider_type: ${customerObject.linked_account.provider_type}
							}
						` : '' }
						${fromMobile ? `mobile_website: 1` : ''}
					}
				) {
					customer {
						customer_id
						firstname
						lastname
						email
						is_approved
						is_age_verified
						created_at
						consent_email_marketing
						consent_mobile
						consent_store_my_data
						consent_profiling
						mostly_used_products
						cigarette_sticks_usage_count
						reason_on_buyglo
						reason_on_buyglo_other
					}
                    yoti_token
				}
			}
		
		`;
		const variablesObj = {
			firstname: customerObject.firstname,
			lastname: customerObject.lastname,
			dob: customerObject.dob,
			gender: customerObject.gender,
			mgm_promo_code: customerObject.mgm_promo_code,
			codice_fiscale: customerObject.codice_fiscale,
			email: customerObject.email,
			password: customerObject.password,
			street: customerObject.street,
			telephone: customerObject.telephone,
			postcode: customerObject.postcode,
			city: customerObject.city,
			region_code: customerObject.region_code,
			region: customerObject.region,
			country_code: customerObject.country_code,
			consent_mobile: customerObject.consent_mobile,
			consent_email_marketing: customerObject.consent_email_marketing,
			consent_profiling: customerObject.consent_profiling,
			consent_store_my_data: customerObject.consent_store_my_data,
			mostly_used_products: customerObject.mostly_used_products,
			cigarette_sticks_usage_count: customerObject.cigarette_sticks_usage_count,
			reason_on_buyglo: customerObject.reason_on_buyglo,
			reason_on_buyglo_other: customerObject.reason_on_buyglo_other,
		};

		if(customerObject.region_of_origin && customerObject.first_glo_device){
			variablesObj.region_of_origin = customerObject.region_of_origin;
			variablesObj.first_glo_device = customerObject.first_glo_device;
		}

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		if (request && request.data && request.data.createCustomerV2 && request.data.createCustomerV2.customer) {
			const { pathname } = new URL(window.location.href);
			const pagename = pathname.split('/').pop();
			
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					UserID: request.data.createCustomerV2.customer.customer_id,
					PageType: pagename,
					Country: 'IT',
					SiteSection: pagename,
					event: 'signUp'
				});				
			}
		}
		sessionStorage.removeItem('customerData');

        if (request && request.data && request.data.createCustomerV2 && request.data.createCustomerV2.yoti_token) {
            localStorage.setItem('yotiToken', request.data.createCustomerV2.yoti_token);
        }

		return request;
	};

	const _createCustomerAddress = async (addressObject) => {
		if (!addressObject) {
			return Promise.resolve('Need Address Object.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation createCustomerAddress(
				$firstname: String!	
				$lastname: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!
				$country_code: CountryCodeEnum
				$default_shipping: Boolean!
				$default_billing: Boolean!
			){
				createCustomerAddress(
					input: {
						firstname: $firstname
						lastname: $lastname
						street: $street
						telephone: $telephone
						postcode: $postcode
						city: $city
						region: {
							region_code: $region,
							region: $region
						}
						country_code: $country_code
						default_shipping: $default_shipping
						default_billing: $default_billing
					}
				) {
					id
					region {
						region
						region_code
					}
					country_code
					street
					telephone
					postcode
					city
					default_shipping
					default_billing
				}
			}
		
		`;
		const variablesObj = {
			firstname: addressObject.firstname,
			lastname: addressObject.lastname,
			street: addressObject.street,
			telephone: addressObject.telephone,
			postcode: addressObject.postcode,
			city: addressObject.city,
			region: addressObject.region,
			country_code: addressObject.country_code,
			default_shipping: addressObject.default_shipping,
			default_billing: addressObject.default_billing
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};

	const _updateCustomerAddress = async (addressObject) => {
		if (!addressObject) {
			return Promise.resolve('Need Address Object.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation updateCustomerAddress(
				$id: Int!
				$firstname: String!	
				$lastname: String!	
				$street: [String!]	
				$telephone: String!	
				$postcode: String!	
				$city: String!	
				$region: String!
				$country_code: CountryCodeEnum
				$default_shipping: Boolean!
				$default_billing: Boolean!
			){
				updateCustomerAddress(
					id: $id,
					input: {
						firstname: $firstname
						lastname: $lastname
						street: $street
						telephone: $telephone
						postcode: $postcode
						city: $city
						region: {
							region_code: $region,
							region: $region
						}
						country_code: $country_code
						default_shipping: $default_shipping
						default_billing: $default_billing
					}
				) {
					id
					region {
						region
						region_code
					}
					country_code
					street
					telephone
					postcode
					city
					default_shipping
					default_billing
				}
			}
		
		`;
		const variablesObj = {
			firstname: addressObject.firstname,
			lastname: addressObject.lastname,
			street: addressObject.street,
			telephone: addressObject.telephone,
			postcode: addressObject.postcode,
			city: addressObject.city,
			region: addressObject.region,
			country_code: addressObject.country_code,
			default_shipping: addressObject.default_shipping,
			default_billing: addressObject.default_billing,
			id: addressObject.id
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};
	
	const _deleteCustomerAddress = async (addressId) => {
		if (!addressId) {
			return Promise.resolve('Need address ID.');
		}
		/*  consent_mobile: $consent_mobile
			consent_email_marketing: $consent_email_marketing
			consent_bat_agreement: $consent_bat_agreement */

		const requestBody = `
			mutation deleteCustomerAddress(
				$id: Int!
			){
				deleteCustomerAddress(
					id: $id
				)
			}
		
		`;
		const variablesObj = {
			id: addressId
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};
	
	const _createCustomerChatBot = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve({ errors: [{ message: 'Need Customer Object.' }] });
		}
		console.log("perform submit with customer object: ", customerObject);
		const signupRequest = await _createCustomerV2(customerObject);
		console.log("signuprequest:", signupRequest);
		if (signupRequest && signupRequest.errors) {
			return signupRequest;
		} else {

			const sourceCartId = Utils.getCookie('commerce-cart-id');
			const authTokenRequest = await _setAuthToken(customerObject.email, customerObject.password);

			if (authTokenRequest && authTokenRequest.errors) {
				return authTokenRequest;
			} else {

				const isLoggedIn = await _checkLogin();

				if (isLoggedIn) {

					const destCartId = Utils.getCookie('commerce-cart-id');

					const loginCustomerOk = await _loginCustomer(sourceCartId, destCartId);

					return loginCustomerOk;
				} else {
					return isLoggedIn;
				}

			}
		}
	}

	const _changeCustomerPreferences = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('No customer object provided!');
		}

		const requestBody = `mutation { 
			updateCustomerV2 (
			  	input: {
					consent_mobile: "${customerObject.consent_mobile}"
					consent_email_marketing: "${customerObject.consent_email_marketing}"
					consent_profiling: "${customerObject.consent_profiling}"
			  	}
			) {
			  	customer {
					firstname
					lastname
					consent_mobile
					consent_email_marketing
					consent_profiling
			  	}
			}
		}`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		console.log("Request",request)
		
		return request;
	};

	const _loyaltyPointsAssignment = async (customerObject) => {
		if (!customerObject) {
			return Promise.resolve('No customer object provided!');
		}

		const requestBody = `
			mutation {
				loyaltyPointAssignment(input: { 
					coupon_code: "${customerObject.coupon_code}"
					customer_id: ${customerObject.customer_id}
				}) {
					status
					message
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	};

	const _loyaltyServiceUpdate = async (choice) => {
		if (!choice) {
			return Promise.resolve('No choice provided!');
		}

		const requestBody = `
			mutation {
				loyaltyServiceUpdate(
					input : {
					  	user_optin_status: "${choice}"
					}
				) {
					result
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');

		return request;
	};

	const _createFanizeLoyaltyUser = async (choice) => {
		if (!choice) {
			return Promise.resolve('No choice provided!');
		}

		const requestBody = `
			mutation {
				createFanizeLoyaltyUser(input: {
					user_optin_status: "${choice}"
				}) {
					status
					message
					validationErrors
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		if (request && request.data && request.data.createFanizeLoyaltyUser && request.data.createFanizeLoyaltyUser.status) {
			const { pathname } = new URL(window.location.href);
			const pagename = pathname.split('/').pop();
			const userData = await _getCustomerDetails();
			
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					UserID: userData.data.customer.customer_id,
					PageType: pagename,
					Country: 'IT',
					SiteSection: pagename,
					event: 'loyaltyOptIn'
				});		
			}
		}
		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	};

	const _customerPaymentTokens = async () => {

		const requestBody = `
			query {
				customerPaymentTokens {
					items {
						details
						public_hash
						payment_method_code
						type
					}
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		let paymentTokens = [];

		if (request && request.data && request.data.customerPaymentTokens) {
			const items = request.data.customerPaymentTokens.items;


			items.forEach(item => {
				let details = JSON.parse(item.details);
				
				let paymentToken = {};
				
				paymentToken.cardEnding = details.maskedCC;
				paymentToken.expireDate = details.expirationDate;
				paymentToken.detailType = details.type;
				paymentToken.schemeTransactionId = details.schemeTransactionId;
				paymentToken.paymentMethodCode = item.payment_method_code;
				paymentToken.publicHash = item.public_hash;
				paymentToken.type = item.type;

				paymentTokens.push(paymentToken);
			});
		}

		return paymentTokens;
	}

	const _removeCustomerPaymentTokens = async (public_hash) => {

		const requestBody = `
			mutation deletePaymentToken($public_hash: String!) {
				deletePaymentToken(public_hash:$public_hash) {
					result
					customerPaymentTokens {
						items {
							details
							public_hash
							payment_method_code
							type
						}
					}
				}
			}
		`;

		const variablesObj = {
			public_hash
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		let paymentTokens = [];
		let result;

		if (request && request.data && request.data.result && request.data.customerPaymentTokens) {
			const items = request.data.customerPaymentTokens.items;

			result = request.data.result;

			items.forEach(item => {
				let details = JSON.parse(item.details);

				let paymentToken = {};

				paymentToken.cardEnding = details.maskedCC;
				paymentToken.expireDate = details.expirationDate;
				paymentToken.detailType = details.type;
				paymentToken.schemeTransactionId = details.schemeTransactionId;
				paymentToken.paymentMethodCode = item.payment_method_code;
				paymentToken.publicHash = item.public_hash;
				paymentToken.type = item.type;

				paymentTokens.push(paymentToken);
			});
		}

		let response = {
			result: result,
			customerPaymentTokens: paymentTokens
		};

		return response;
	}

	const _customerMgmService = async () => {

		const requestBody = `
			mutation {
				customerMgmService {
					mgm_code
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	};

	const _blogPostInfo = async (postId) => {

		if (!postId) {
			return Promise.resolve('No post ID provided!');
		}

		const requestBody = `
			query {
				blogPostInfo(id: ${postId}) {
				  	vote_coin_label
				  	vote_coin_code
				  	vote_coin_points
				  	vote_action_status
				  	video_action_status
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _blogPostActionLike = async (postId, action, postTitle) => {

		if (!postId || !action) {
			return Promise.resolve('No post ID or action provided!');
		}

		const requestBody = `
			mutation{
				blogPostSubmit(
				input:{
					post_id: ${postId}
					post_title: "${postTitle.replaceAll('\"', '').replaceAll('\'', '').replaceAll('“', '').replaceAll('”', '')}"
					action: "${action}"
				}){
					status
					prevActionAvailable
					errorMessage
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}

	const _blogPostActionVideo = async (postId, value, postTitle) => {

		if (!postId || !value) {
			return Promise.resolve('No post ID or value provided!');
		}

		const requestBody = `
			mutation{
				blogPostSubmit(
				input:{
					post_id: ${postId}
					post_title: "${postTitle}"
					action: "watch"
					video_coin_type:"${value}"
				}) {
					status
					prevActionAvailable
					errorMessage
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}

	const _loyaltyUserInfo  = async () => {

		const customerData = localStorage.getItem('loyaltyInfo');

		if (customerData && customerData !== 'undefined') {
			const { datas, expires } = JSON.parse(customerData);
			if (!datas || !expires || expires < Date.now()) {
				localStorage.removeItem('loyaltyInfo');
			} else {
				return datas;
			}
		}

		const requestBody = `
			query {
				loyaltyUserInfo {
					first_name
					last_name
					primary_email
					idm_id
					user_tier
					tier_completed_percentage
					points
					coins
					loyalty_card {
						code
						expiration_date
					}
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		const saveLoyalty = {
			expires: Math.round(LOYALTY_CACHE_TIME + Date.now()),
			datas: request
		};

		localStorage.setItem('loyaltyInfo', JSON.stringify(saveLoyalty));

		return request;
	}

	const _syncOfflineDevices = async () => {

		const requestBody = `
			mutation {
				syncOfflineDevices
				{
					status
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _updateSalesforceId = async () => {

		const requestBody = `
			mutation{
				updateSalesforceId{
					salesforce_customer_id
					status
				}
			}
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _loyaltyUserTier  = async () => {

		const requestBody = `
			query {
				loyaltyUserInfo {
					user_tier
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _pointsHistory  = async () => {

		const requestBody = `
			query {
				pointsHistory {
					type
					title
					coins
					points
					created_at
				}
			}			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _getMissionList  = async (isMobile) => {

		const customerData = localStorage.getItem('missionList');
		const isApp = window.location.href.indexOf('/app') > -1;

		const checkSource = (source) => (isApp && source === 'app') || (!isApp && source === 'web');

		/*if (customerData && customerData !== 'undefined') {
			const { datas, expires, source } = JSON.parse(customerData);
			if (!datas || !expires || expires < Date.now() || !checkSource(source)) {
				localStorage.removeItem('missionList');
			} else {
				return datas;
			}
		}*/

		const requestBody = `
			query {
				getMissionList${isMobile ? '(accessPoint:"mobileapp")' : ''} {
					mission_type
					id
					points
					max_points
					campaign_id
					created_at
					updated_at
					claim
					available
					type
					title
					cta
					content
					image
					answered_points
					correct_points
					max_seconds
					total_questions
					download_link
				}
			}		  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		const saveLoyalty = {
			expires: Math.round(LOYALTY_CACHE_TIME + Date.now()),
			source: isApp ? 'app' : 'web',
			datas: request
		};

		localStorage.setItem('missionList', JSON.stringify(saveLoyalty));

		return request;
	}

	const _getQuestions  = async (surveyID) => {

		if (!surveyID) {
			return Promise.resolve('No Survey ID provided!');
		}

		const requestBody = `
			query {
				getQuestions(surveyId: "${surveyID}") {
					id
					type
					order
					survey_id
					image
					text
					options {
						id
						type
						order
						parent_survey_question_id
						survey_id
						image
						text
					}
				}
			}		  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}


	const _getPredictions  = async (predictionID) => {

		if (!predictionID) {
			return Promise.resolve('No Prediction ID provided!');
		}

		const requestBody = `
		query{
			getPredictions(
			  predictionId:"${predictionID}"
			){
			  id
			  type
			  order
			  parent_prediction_question_id
			  correct_prediction_question_id
			  points
			  prediction_id
			  created_at
			  updated_at
			  deleted_at
			  image
			  text
			  options {
				id
				type
				order
				parent_prediction_question_id
				correct_prediction_question_id
				points
				prediction_id
				image
				text
			  }
			}
		  }	  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _getInformationContent  = async (informationID) => {

		if (!informationID) {
			return Promise.resolve('No infromation ID provided!');
		}

		const requestBody = `
			query {
				getInformationContent(informationId: "${informationID}") {
					cta
					content
					image_url
				}
			}		  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		return request;
	}

	const _predictionSubmission  = async (predictionID, parent_prediction_questionID,answer) => {

		if (!predictionID) {
			return Promise.resolve('No prediction ID provided!');
		}


		const requestBody = `
		mutation{
			predictionSubmission(
			  predictionId:"${predictionID}"
			  answers:{
				  id:"${parent_prediction_questionID}"
				  answer:"${answer}"
				}
			){
			  status
			  message
			}
		  }			  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}

	const _saveInformation  = async (informationID) => {

		if (!informationID) {
			return Promise.resolve('No information ID provided!');
		}


		const requestBody = `
		mutation {
			markInformationRead(
						informationId: "${informationID}") {
							status
							message
					}
			}				  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}

	const _saveAnswers  = async (surveyID, answers) => {

		if (!surveyID) {
			return Promise.resolve('No Survey ID provided!');
		}

		let stringAnswers = '';

		answers.forEach((el, index) => {
			stringAnswers += `{
				id: "${el.id}",
				answer: "${el.answer}"
			}${index < (answers.length - 1) ? ',' : ''}`;
		});

		const requestBody = `
			query {
				saveAnswers(
					surveyId: "${surveyID}"
					answers: [${stringAnswers}]
				) {
				status
				message
				}
			}				  
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}

	const _getDownloadContent  = async (downloadID) => {

        if (!downloadID) {
            return Promise.resolve('No download ID provided!');
        }

        const requestBody = `
            query {
                getDownloadLink(downloadId: "${downloadID}") {
                    link
                    points
                }
            }            
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

        return request;
    }

	const _getQuizQuestions  = async (quizID, quizType) => {

        if (!quizID) {
            return Promise.resolve('No Quiz ID provided!');
        }

        const requestBody = `
            query {
                getQuizQuestions(
				quizId: "${quizID}"
				${quizType ? `quizType: "${quizType}"` : ''})
	{
                    id
                    question
                    image
                    answers {
                        id
                        text
                    }
                }
            }        
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);

        return request;
    }

	const _scanCodes  = async (qrID, qrValue) => {

		if (!qrID) {
			return Promise.resolve('No QR ID provided!');
		}


		const requestBody = `
			query {
				scanCodes(
					scanId:"${qrID}",
					qrcode:"${qrValue}"
				){
					status
					message
					points
					coins
				}
			} 
		`;

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}


    const _getQuizDetails  = async (quizID, quizType) => {

        if (!quizID) {
            return Promise.resolve('No Quiz ID provided!');
        }

        const requestBody = `
            query {
                getQuizDetails(
				quizId: "${quizID}"
				${quizType ? `quizType: "${quizType}"` : ''}) 
				{
                    questions_count
                    max_seconds_per_question
                    answered_points
                    correct_points
                    all_done_points
                    all_done_correct_points
                    min_correct_answers
                }
            }            
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

        return request;

    }


	const _saveQuiz  = async (quizID, quizAnswer, time, quizType) => {

        if (!quizID) {
            return Promise.resolve('No quiz ID provided!');
        }

        const requestBody = `  
            mutation {
                validateQuizAnswer(quizId: "${quizID}"
				${quizType ? `quizType: "${quizType}"` : ""}
                input:{
                        answer:"${quizAnswer}"
                        seconds:${time}}
                ){
                    status
                    message
					correct
                }
            }                
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);
        localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

        return request;
    }

	const _getQuizPointsPrelive  = async (quizID, quizType) => {

        if (!quizID) {
            return Promise.resolve('No Quiz ID provided!');
        }

        const requestBody = `
			query{
				getQuizPoints(
					quizId:"${quizID}"
					${quizType ? `quizType: "${quizType}"` : ''}
				){
					points
					message
					feedback
				}
			}          
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

        return request;

    }

	const _getQuizPoints  = async (quizID, quizType) => {

        if (!quizID) {
            return Promise.resolve('No Quiz ID provided!');
        }

        const requestBody = `
			query{
				getQuizPoints(
					quizId:"${quizID}"
					quizType:"${quizType}"
				){
					points
				}
			}          
        `;

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

        return request;

    }

	const _getVideoContent  = async (videoID) => {

		if (!videoID) {
			return Promise.resolve('No video ID provided!');
		}
	
		const requestBody = `
				query {
					getVideoInfo(videoId: "${videoID}") {
						id
						label
						min_seconds
						points
						cta
						link	
					}
				}		  
		`;
	
		const query = JSON.stringify({
			query: requestBody
		});
	
		const request = await _makePostRequest(query);
	
		return request;
	}
	
	const _saveVideo  = async (videoID) => {
	
		if (!videoID) {
			return Promise.resolve('No video ID provided!');
		}
	
	
		const requestBody = `
		mutation {
			markVideoSeen(videoId: "${videoID}") {
				status
				message
				points
				
			}
		}					  
		`;
		
	
		const query = JSON.stringify({
			query: requestBody
		});
	
		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');
	
		return request;
	}

	const _pictureUpdate  = async (picID, caption, name, file) => {
	
		if (!picID) {
			return Promise.resolve('No mission ID provided!');
		}
	
	
		const requestBody = `
		mutation{
			pictureUpload(
				pictureMissionId:"${picID}"
				input:{
					caption:"${caption}"
					img_name:"${name}"
					encoded_img:"${file}"
				} 	
			){
				status
				message
				coins
				points
				picture
			}
		}				  
		`;
		
	
		const query = JSON.stringify({
			query: requestBody
		});
	
		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');
	
		return request;
	}

	const _fanCamStickers  = async () => {

		const customerData = localStorage.getItem('funcamStickers');

		if (customerData && customerData !== 'undefined') {
			const { datas, expires } = JSON.parse(customerData);
			if (!datas || !expires || expires < Date.now()) {
				localStorage.removeItem('funcamStickers');
			} else {
				return datas;
			}
		}
	
		const requestBody = `
			query {
				funCamStickers{
					id
					url
				}
			}		  
		`;
	
		const query = JSON.stringify({
			query: requestBody
		});
	
		const request = await _makePostRequest(query);

		const saveLoyalty = {
			expires: Math.round(LOYALTY_CACHE_TIME + Date.now()),
			datas: request
		};

		localStorage.setItem('funcamStickers', JSON.stringify(saveLoyalty));
	
		return request;
	}

	const _fanCamPictureUpload  = async (picID, name, file) => {
	
		if (!picID) {
			return Promise.resolve('No mission ID provided!');
		}
	
	
		const requestBody = `
		mutation{
			pictureUpload(
				pictureMissionId:"${picID}"
				input:{
					img_name:"${name}"
					type:"funcam"
					encoded_img:"${file}"
				} 	
			){
				status
				message
				coins
				points
				picture
			}
		}				  
		`;
		
	
		const query = JSON.stringify({
			query: requestBody
		});
	
		const request = await _makePostRequest(query);
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');
	
		return request;
	}

	const _getStores = async (filter) => {

		if(window.localforage && (!filter || (!filter.store && !filter.activity))) {
			const storeDatas = await localforage.getItem('allStores');
			if(storeDatas) {
				const { allStores, expires } = storeDatas;
				if(!expires || expires < Date.now()) {
					localforage.removeItem('allStores');
				} else if(allStores && allStores.length > 0) {
					return allStores;
				}
			}
		}

		let stringFilter = "";

		if (filter && filter.store) {
			stringFilter = `store_type: { eq: "${filter.store}" }`;
		}

		else if (filter && filter.activity) {
			stringFilter = `activity_type: {eq: "${filter.activity}"}`;
		}

		const requestBody = 
		`
			query {
				storeLocatorFilter(
				filters: { 
					${stringFilter}
				} ) {
					physicalStores {
						online
						title
						company_name
						address
						city
						state
						zipcode
						country_code
						phone
						latitude
						longitude
						store_type
						activity_type
					}
				}
			}
		`;

		const request = await _makeGetRequest(requestBody);

		if (request && request.data && request.data.storeLocatorFilter) {
			const physicalStores = request.data.storeLocatorFilter.physicalStores;
			if(window.localforage && (!filter || (!filter.store && !filter.activity))) {
				const dataToStore = {
					allStores: physicalStores,
					expires: Math.round(86400 * 1000 + Date.now())
				}
				await localforage.setItem('allStores', dataToStore);
			}
			return physicalStores;
		} 
		else {
			return null;
		}

	}

	const _getBlogPosts = async () => {

		const requestBody = 
		`
			{
				blogPosts(currentPage: 1, pageSize: 500, sort: ["DESC"]) {
					total_count
					items {
						post_id
						identifier
						title
						canonical_url
						post_url
						creation_time
					}
					total_pages
				}
			}
		`;
		const request = await _makeGetRequest(requestBody);

		if (request && request.data && request.data.blogPosts) {
			const blogPosts = request.data.blogPosts.items;
			return blogPosts;
		} 
		else {
			return null;
		}

	}


	const _newOtpRequest  = async (email, phone, newphone) => {

		if (!email || !phone) {
			return Promise.resolve('Missing phone number or email');
		}
		
		let requestBody;
		if(newphone != "+39" && newphone != phone){
			requestBody = `  
				mutation{
					OtpRequest(
						email: "${email}"
						number: "${phone}"
						newnumber: "${newphone}"
					){
						message	
						status
					}
				}`;
		}else{
			requestBody = `  
			mutation{
				OtpRequest(
					email: "${email}"
					number: "${phone}"
				){
					message	
					status
				}
			}`;
		}

       

        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);

        return request;
    }

	const _newOtpVerify = async (email,phone,otpCode) => {

		if (!email || !phone || !otpCode) {
			return Promise.resolve('Missing email, phone number or OTP code.');
		}

		const requestBody = `
			mutation {
				OtpVerify (
					email: "${email}",
					number: "${phone}",
					otp: "${otpCode}"
				){
					status 
					message 
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _delayedOtpRequest  = async (customerId,token,newphone) => {

		if (!customerId || !token) {
			return Promise.resolve('Missing phone customerId or token');
		}

		let requestBody;
		if(newphone != "+39"){
			requestBody = `  
				mutation{
					OtpRequest(
						customer_id: "${customerId}"
						token: "${token}"
						newnumber: "${newphone}"
					){
						message	
						status
					}
				}`;
		}else{
			requestBody = `  
				mutation{
					OtpRequest(
						customer_id: "${customerId}"
						token: "${token}"
					){
						message	
						status
					}
				}`;
		}


		
        const query = JSON.stringify({
            query: requestBody
        });

        const request = await _makePostRequest(query);

        return request;
    }

	const _delayedOtpVerify = async (customerId,token,otpCode) => {

		if (!customerId || !token || !otpCode) {
			return Promise.resolve('Missing customerId, token or OTP code.');
		}

		const requestBody = `
			mutation {
				OtpVerify (
					customer_id: "${customerId}",
					token: "${token}",
					otp: "${otpCode}"
				){
					status 
					message 
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _validateGloNumber = async (gloNumber) => {
		const requestBody = `query {
			validateDevice (
				input: {
					device_id: "${gloNumber}"
				}
		) {
				status
				message
			}
		}`;

		console.log("requestBody", requestBody);

		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
	
		if (request && request.data && request.data.validateDevice && request.data.validateDevice.status) {
			return request.data.validateDevice.status;
		}
		else {
			return false;
		}
	}

	const _getSocialLoginInfo = async () => {

		const requestBody = `
			query {
				moduleConfig {
					social_login {
						enabled
						configuration {
							facebook_enabled
							facebook_sort_order
							facebook_app_id
							google_enabled
							google_sort_order
							google_app_id
						}
					}
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _socialLogin = async (socialLoginObject) => {

		const requestBody = `

			mutation gloItalySocialLogin(
				$type: GloItalySocialTypeEnum!,
				$email: String!,
				$social_id: String!
			){
				gloItalySocialLogin(
					input:{
						type: $type,
						email: $email,
						social_id: $social_id
					}
				){
					token
				}
			}
		`;

		const variablesObj = {
			"type": socialLoginObject.type,
			"email": socialLoginObject.email,
			"social_id": socialLoginObject.social_id
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.gloItalySocialLogin.token;
				_setCommerceCookie('commerce-auth-token', authToken, 1440);
				sessionStorage.removeItem('customerData');
				localStorage.removeItem('loyaltyInfo');
				localStorage.removeItem('missionList');
				if (window.location.href.indexOf('/app/') > -1 && window.localforage) {
					window.localforage.setItem('loyalty', socialLoginObject);
				}
				return { authToken };
			}
			if (window.location.href.indexOf('/app/') > -1 && window.localforage) {
				window.localforage.removeItem('loyalty');
			}
			return { errors };
		});

		return request;
	};

	const _optOutFanizeLoyaltyUser = async () => {

		const requestBody = `
			mutation {
				optOutFanizeLoyaltyUser(input: {
			  		user_optout_status: "Yes"
				}) {
			  		status
			  		message
				}
		  	}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);
		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		if (request && request.data && request.data.optOutFanizeLoyaltyUser && request.data.optOutFanizeLoyaltyUser.status) {
			const { pathname } = new URL(window.location.href);
			const pagename = pathname.split('/').pop();
			const userData = await _getCustomerDetails();
			
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					UserID: userData.data.customer.customer_id,
					PageType: pagename,
					Country: 'IT',
					SiteSection: pagename,
					event: 'loyaltyOptOut'
				});		
			}
		}

		return request;
	}

	const _otpRequest = async (phone) => {

		if (!phone) {
			return Promise.resolve('Missing phone number.');
		}

		const requestBody = `
			mutation {
				OtpRequest(number: "${phone}") {
					status
					message
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _otpVerify = async (phone, code) => {

		if (!phone || !code) {
			return Promise.resolve('Missing phone number or OTP code.');
		}

		const requestBody = `
			mutation {
				OtpVerify (
					number: "${phone}",
					otp: "${code}"
				){
					status 
					message 
				}
			}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _getproductReviewStatus = async (sku) =>{
		const requestBody = `query {
			productReviewStatus(sku: "${sku}") {
				status
				label
				has_orders_or_device
			}
		}`;


		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);
	
		return request;
	}

	const _salesforceRegistration = async (customerObject) => {
		
		const requestBody = `
		  mutation simplifiedRegistration (
			  $codice_fiscale: String!	
			  $password: String!	
			  $salesforce_id: String!
			  $loyalty_optin: Int!
		  ){
				simplifiedRegistration (
				  input: {
					  password: $password
					  codice_fiscale: $codice_fiscale
					  salesforce_id: $salesforce_id,
					  loyalty_optin: $loyalty_optin
				  }
			  ) {
				  customer {
					  firstname
					  lastname
					  email
				  }
			  }
		  }
	  
	  `;
		const variablesObj = {
				codice_fiscale: customerObject.codice_fiscale,
				password: customerObject.password,
				street: customerObject.street,
				postcode: customerObject.postcode,
				city: customerObject.city,
				country_code: customerObject.country_code,
				salesforce_id: customerObject.salesforce_id,
				loyalty_optin: customerObject.loyalty_optin
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		if (request && request.data && request.data.simplifiedRegistration && request.data.simplifiedRegistration.customer) {
			const { pathname } = new URL(window.location.href);
			const pagename = pathname.split('/').pop();
			
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					UserID: request.data.simplifiedRegistration.customer.customer_id,
					PageType: pagename,
					Country: 'IT',
					SiteSection: pagename,
					event: 'signUp'
				});				
			}
		}

		console.log("Request",request)
		
		return request;
	};

	const _validateCustomer = async (customerObject) => {
		
		const requestBody = `
		  mutation validatecustomer (
			  $codice_fiscale: String!	
			  $email: String!	
			  $number: String!
		  ){
			validatecustomer (
				email: $email
				fiscalcode: $codice_fiscale
				number: $number
			  ) {
				email
				fiscalcode
				number
			  }
		  }
	  
	  `;
		const variablesObj = {
			codice_fiscale: customerObject.codice_fiscale,
			email: customerObject.email,
			number: customerObject.number
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		
		return request;
	};

	const _getWorldPayInfo = async () => {
		const requestBody = `{
			moduleConfig {
				worldpay {
					configuration {
						apm_enabled
						cc_cse_public_key
						cc_enabled
						cc_require_cvc
						tokenization_enabled
					}
					enabled
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	}

	const _applyCouponToCart = async (couponCode) => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
			mutation applyCouponToCart($cartId: String!, $couponCode: String!) {
				applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
					cart {
						applied_coupons {
							code
						}
					}
				}
			}  
	 	`;
		const variablesObj = {
			cartId,
			couponCode
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		const cart = await _getCart(cartId);
		
		return request;
	};

	const _setWorldPayCCPaymentMethodOnCart = async (ccType, encryptedData) => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
			mutation setPaymentMethodOnCart(
				$cartId: String!
				$ccType: String!
				$encryptedData: String!
			) {
				setPaymentMethodOnCart(
					input: {
						cart_id: $cartId
						payment_method: {
							code: "worldpay_cc"
							worldpay_cc: {
								is_saved_card_payment: false
								cc_type: $ccType
								encrypted_data: $encryptedData
								save_my_card: false
							}
						}
					}
				) {
					cart {
						selected_payment_method {
							code
							title
						}
					}
				}
			}
	 	`;
		const variablesObj = {
			cartId,
			ccType,
			encryptedData
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query, true);
		
		return request;
	};

	const _setCoinsPaymentMethodOnCart = async () => {

		if (window.localforage) {
			const loginData = await window.localforage.getItem('loyalty');
			if (loginData && loginData.social_id) {
				await _socialLogin(loginData);
			} else if (loginData && loginData.key) {
				await _setAuthToken(loginData.email, loginData.key);
			}
		}

		await _checkLogin();

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
			mutation setPaymentMethodOnCart(
				$cartId: String!
			) {
				setPaymentMethodOnCart(
					input: {
						cart_id: $cartId
						payment_method: {
							code: "bat_insiders_coin"
						}
					}
				) {
					cart {
						selected_payment_method {
							code
							title
						}
					}
				}
			}
	 	`;
		const variablesObj = {
			cartId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query, true);
		
		return request;
	};

	const _setShippingAddressesOnCart = async (addressId) => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
		mutation setShippingAddressesOnCart(
			$cartId: String!
			$addressId: Int!
		) {
			setShippingAddressesOnCart(
			  input: {
				cart_id: $cartId
				shipping_addresses: [
				  {
					customer_address_id: $addressId
				  }
				]
			  }
			) {
			  cart {
				shipping_addresses {
				  firstname
				  lastname
				  company
				  street
				  city
				  region {
					code
					label
				  }
				  postcode
				  telephone
				  country {
					code
					label
				  }
				}
			  }
			}
		  }
	 	`;
		const variablesObj = {
			cartId,
			addressId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		const billing = await _setBillingAddresseOnCart(addressId);
		
		return request;
	};

	const _setBillingAddresseOnCart = async (addressId) => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
		mutation setBillingAddressOnCart(
			$cartId: String!
			$addressId: Int!
		) {
			setBillingAddressOnCart(
			  input: {
				cart_id: $cartId
				billing_address: {
					customer_address_id: $addressId
				}
			  }
			) {
			  cart {
				billing_address {
					firstname
					lastname
					company
					street
					city
					region {
					  code
					  label
					}
					postcode
					telephone
					country {
					  code
					  label
					}
				}
			  }
			}
		  }
	 	`;
		const variablesObj = {
			cartId,
			addressId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		
		return request;
	};

	const _setFreeShippingMethodOnCart = async () => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
		mutation setShippingMethodsOnCart($cartId: String!) {
			setShippingMethodsOnCart(
			  input: {
				cart_id: $cartId
				shipping_methods: {
				  carrier_code: "freeshipping"
				  method_code: "freeshipping"
				}
			  }
			) {
			  cart {
				shipping_addresses {
				  selected_shipping_method {
					method_code
					method_title
				  }
				}
			  }
			}
		  }
	 	`;
		const variablesObj = {
			cartId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		
		return request;
	};

	const _setMatrixShippingMethodOnCart = async () => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
		mutation setShippingMethodsOnCart($cartId: String!) {
			setShippingMethodsOnCart(
			  input: {
				cart_id: $cartId
				shipping_methods: {
				  carrier_code: "matrixrates"
				  method_code: "metodo_di_spedizione_standard"
				}
			  }
			) {
			  cart {
				shipping_addresses {
				  selected_shipping_method {
					method_code
					method_title
				  }
				}
			  }
			}
		  }
	 	`;
		const variablesObj = {
			cartId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		
		return request;
	};

	const _placeOrder = async () => {

		const cartId = Utils.getCookie('commerce-cart-id');
		
		if (!cartId) {
			return Promise.resolve('Missing cart ID.');
		}

		const requestBody = `
			mutation placeOrder($cartId: String!) {
				placeOrder(
					input: {
						cart_id: $cartId
					}
				) {
					order {
						order_number
					}
					action {
						worldpay_threed_secure {
							pa_req
							url
						}
					}
				}
			} 
	 	`;
		const variablesObj = {
			cartId
		};

		const query = JSON.stringify({
				query: requestBody,
				variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		DataLayerUtils.trackOrderCompleted();
		_checkLogin();
		
		return request;
	};

	const _getContactId = async (email, customer_id) => {

		if (!customer_id) {
			return Promise.resolve('Missing customer ID.');
		}

		const requestBody = `
			mutation {
				PersonContactId (
				  	email: "${email}"
				  	customer_id: "${customer_id}"
			  	) {
					PersonContactId
			  	}
		  	}
	 	`;

		const query = JSON.stringify({
				query: requestBody
		});

		const request = await _makePostRequest(query);
		
		return request;
	};

	const _getProductReviewsByPage = async (productSku,currentPage,pageSize) => {
		// Catch no productId.
		if (!productSku) {
			return Promise.resolve('Need Product Sku');
		}

		const requestBody = `{
            products(filter: { sku: {eq: "${productSku}"} }) {
				items {
					name
					sku
					rating_summary
					review_count
					reviews(currentPage: ${currentPage}, pageSize: ${pageSize}) {
						total_count
						items {
							average_rating
							nickname
							summary
							text
							loyalty_user_review
							ratings_breakdown {
								name
								value
							}
							created_at
						}
					}
				}
				total_count
				page_info {
				  	page_size
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};


	const _getPanels = async () => {

		const requestBody = `{
			products(
				pageSize: 100
      			currentPage: 1
			   	filter: {category_id: { eq: "331"} }
		   	) {
			   	total_count
			   	items {
					sku
					related_products{
						sku
						name
					}
					__typename
					
					... on ConfigurableProduct {
						variants {
							product {
								uid
								name
								sku
								media_gallery{
										url
									}
							}
						}
					}
			   	}
	   
				page_info {
					page_size
				}
		   	}
	   	}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _getConfigurableProductsByListOfSKU = async (listOfSKU) => {
		
		createListOfSKUString = (listOfSKU) => {
			let string = '';
			if (listOfSKU) {
				if (listOfSKU.length > 1) {
					listOfSKU.forEach((current_sku) => {
						string += `"${current_sku}",`;
					});
					return `
						sku: { 
							in: [${string}] 
						}
					`;
				} else if (listOfSKU.length === 1) {
					string += `"${listOfSKU[0]}"`;
					return `
						sku: { 
							eq: ${string} 
						}
					`;
				}
				
				console.log("STRING",string);
			}
			return string;
		};

		const requestBody = `{
			products(filter: { ${createListOfSKUString(listOfSKU)}}) {
				items {
					uid
					name
					sku			
					__typename
					... on ConfigurableProduct {
						configurable_options {
							attribute_code
							values {
								uid
								label
								store_label
								default_label
								use_default_value
								swatch_data {
									value
								}
							}
						}
						variants {
							product {
								uid
								name
								sku
								swatch_image
								
							}
							attributes {
								label
								code
								uid
							}
						}
					}
					price_range {
						minimum_price {
							regular_price {
								value
								currency
							}
							discount {
								amount_off
								percent_off
							}
							final_price {
								value
								currency
							}
						}
					}
				}
				total_count
				page_info {
					page_size
				}
			}
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	const _contactUsMetadata = async () => {

		const requestBody = `
		query {
			moduleConfig {
			contact_us {
			enabled
			}
			}
		   }
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	};

	const _contactUsPostAction = async (form) => {

		if (!form.name || !form.email || !form.comment) {
			return Promise.resolve('No name or email or comment provided!');
		}

		const requestBody = `
			mutation{
				contactUs(
					name: "${form.name}"
					email: "${form.email}"
					telephone: "${form.telephone}"
					comment: "${form.comment}"
					){
						result
						}
				
			}
		`;
		const query = JSON.stringify({
			query: requestBody
		});

		const request = await _makePostRequest(query);

		sessionStorage.removeItem('customerData');
		localStorage.removeItem('loyaltyInfo');
		localStorage.removeItem('missionList');

		return request;
	}



	const _getGlopinsLocations = async () => {

		const requestBody = `
			query{
				getAllUserLocations {
				  category
				  city
				  created_at
				  description
				  id
				  latitude
				  longitude
				  name
				  place
				  short_description
				}
			  }			  
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _getAllEventsAndLounge= async () => {

		const requestBody = `
		query
			{
				getAllEventAndLounge(
				order_by: "date"
				all: 0
				) {
				category {
					id
					name
				}
				cover
				created_at
				date_publish
				dates {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
				}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
					}
					max_attendees
					note
					status
				}
				deleted_at
				description
				id
				name
				short_description
				type
				}
			}
  
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}
	
	const _getOneEventAndLounge = async (id) => {

		const requestBody = `
		query
			{
				getOneEventAndLounge(show_one: ${id}) {
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
					event_date {
					created_at
					date
					deleted_at
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
					event_hour {
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
					}
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user_id
				}
				}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _getRegisteredEventNext = async () => {

		const requestBody = `
		query
			{
			getRegisteredEventNext {
				attendance_date
				cancel_date
				created_at
				event {
				cover
				created_at
				date_publish
				deleted_at
				description
				id
				name
				short_description
				type
				}
				event_date {
				created_at
				date
				deleted_at
				hours{
				created_at
				date
				deleted_at
				end_time
				id
				max_attendees
				start_time
				status
				}
				id
				location{
					id 
					name 
					category 
					place 
					longitude 
					latitude 
					short_description 
					description 
					created_at 
					city 
					}
				max_attendees
				note
				status
				}
					event_hour {
				id 
				start_time
				end_time 
				date 
				status
				created_at
				max_attendees 
			}
				event_date_date
				guest_birthday
				guest_document
				guest_email
				guest_gender
				guest_lastname
				guest_mobile_number
				guest_name
				id
				loyalty_document
				qr_code_image
				qr_code_link
				qr_code_uuid
				registration_date
				status
				user {
				birthday
				created_at
				email
				email_verified_at
				firstname
				gender
				id
				idm_id
				lastname
				loyalty_card_code
				loyalty_card_expiration_date
				loyalty_id
				mobile_number
				updated_at
				}
				user_id
			}
			}



							
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _getRegisteredEventComing = async () => {

		const requestBody = `
		query{
			getRegisteredEventComing {
			  attendance_date
			  cancel_date
			  created_at
			  event {
				cover
				created_at
				date_publish
				deleted_at
				description
				id
				name
				short_description
				type
			  }
			  event_date {
				created_at
				date
				deleted_at
				hours{
				created_at
				date
				deleted_at
				end_time
				id
				max_attendees
				start_time
				status
			  }
				id
				location{
					id 
					name 
					category 
					place 
					longitude 
					latitude 
					short_description 
					description 
					created_at 
					city 
				  }
				max_attendees
				note
				status
			  }
			  event_date_date
			  event_hour {
				created_at
				date
				deleted_at
				end_time
				id
				max_attendees
				start_time
				status
			  }
			  guest_birthday
			  guest_document
			  guest_email
			  guest_gender
			  guest_lastname
			  guest_mobile_number
			  guest_name
			  id
			  loyalty_document
			  qr_code_image
			  qr_code_link
			  qr_code_uuid
			  registration_date
			  status
			  user {
				birthday
				created_at
				email
				email_verified_at
				firstname
				gender
				id
				idm_id
				lastname
				loyalty_card_code
				loyalty_card_expiration_date
				loyalty_id
				mobile_number
				updated_at
			  }
			  user_id
			}
		  }
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

    const _gloersContest = async (imgName, base64) => {

        const requestBody = `
        mutation{
            GloersContest(
                input:{
                    caption:"${imgName}"
                    img_name:"${imgName}"
                    encoded_img:"${base64}"
                }   
            ){
                status
                message
            }
          }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }
    
    const _getRegisteredEvents = async () => {
    
		const requestBody = `
			query
				{
				getRegisteredEvent(
					all: 1
				) {
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
						event_hour {
									id 
									start_time
									end_time 
									date 
									status
									created_at
									max_attendees 
						}
					event_date {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
					}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
					event_date_date
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
					}
					user_id
				}
				}

		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

    const _gloersContestCheck = async () => {

        const requestBody = `
            query{
                GloersContestCheck{
                    upload_completed
                }
            }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }
    
    const _getNotifications = async () => {

		const requestBody = `
		mutation {
			getNotifications {
			  attendee {
				attendance_date
				cancel_date
				created_at
				event{
				  id 
			cover 
			name 
			description 
			date_publish 
			type 
			created_at 
			deleted_at 
			short_description 
				}
				event_date{
				  id 
			deleted_at 
			date 
			status 
			created_at 
			max_attendees 
				}
				event_hour{
				created_at
				date
				deleted_at
				id
				max_attendees
				status
			  }
				guest_birthday
				guest_document
				guest_email
				guest_gender
				guest_lastname
				guest_mobile_number
				guest_name
				id
				loyalty_document
				qr_code_image
				qr_code_link
				qr_code_uuid
				registration_date
				status
				user_id
			  }
			  created_at
			  description
			  event {
				cover
				created_at
				date_publish
				deleted_at
				description
				id
				name
				short_description
				type
			  }
			  event_date {
				created_at
				date
				deleted_at
				end_time
				id
				max_attendees
				start_time
				status
			  }
			  event_hour {
				created_at
				date
				deleted_at
				end_time
				id
				max_attendees
				start_time
				status
			  }
			  id
			  read
			  reason
			  title
			  user_id
			}
		  }
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _deleteNotifications = async (id, bulk_delete) => {
		const requestBody = `mutation {
		deleteNotifications(
			${id ? `id: "${id}"` : ''}
			${bulk_delete ? `bulk_delete: "${bulk_delete}"` : ''}
  		){
    		status
    		message
  		}
	  }`;

	  const query = JSON.stringify({ query: requestBody });

	  const request = await _makePostRequest(query);

	  return request;
	}
	
	const _readNotifications = async (id) => {
    
    		const requestBody = `
			mutation {
				readNotifications(id:${id}) {
				  attendee {
					attendance_date
					cancel_date
					created_at
					event{
					  id 
				cover 
				name 
				description 
				date_publish 
				type 
				created_at 
				deleted_at 
				short_description 
					}
					event_date{
					  id 
				deleted_at 
				 
				date 
				status 
				created_at 
				max_attendees 
					}
					event_hour{
					created_at
					date
					deleted_at
					
					id
					max_attendees
					
					status
				  }
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user_id
				  }
				  created_at
				  description
				  event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
				  }
				  event_date {
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
				  }
				  event_hour {
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
				  }
				  id
				  read
				  reason
				  title
				  user_id
				}
			  }
    		`;
    
    		const query = JSON.stringify({ query: requestBody });
    
    		const request = await _makePostRequest(query);
    
    		return request;
    	}
    	
	const _cancelRegisterEvent = async (eventDateId) => {
		
		const requestBody = `
			mutation {
				cancelRegisterEvent(event_date_id: ${eventDateId}) {
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
					event_date {
					created_at
					date
					deleted_at
					hours{
						id 
				deleted_at 
				start_time 
				end_time 
				date 
				status 
				created_at 
				max_attendees 
					}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
					}
					user_id
				}
				}
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}
		
	const _cancelRegisterLounge = async (eventHourId) => {
				
		const requestBody = `
		mutation {
			cancelRegisterEventHours(event_hour_id: "${eventHourId}") {
				attendance_date
				cancel_date
				created_at
				event {
				cover
				created_at
				date_publish
				deleted_at
				description
				id
				name
				short_description
				type
				}
				event_date {
				created_at
				date
				deleted_at
				hours{
					id 
			deleted_at 
			start_time 
			end_time 
			date 
			status 
			created_at 
			max_attendees 
				}
				id
				location{
					id 
					name 
					category 
					place 
					longitude 
					latitude 
					short_description 
					description 
					created_at 
					city 
					}
				max_attendees
				note
				status
				}
				guest_birthday
				guest_document
				guest_email
				guest_gender
				guest_lastname
				guest_mobile_number
				guest_name
				id
				loyalty_document
				qr_code_image
				qr_code_link
				qr_code_uuid
				registration_date
				status
				user {
				birthday
				created_at
				email
				email_verified_at
				firstname
				gender
				id
				idm_id
				lastname
				loyalty_card_code
				loyalty_card_expiration_date
				loyalty_id
				mobile_number
				updated_at
				}
				user_id
			}
			}
			
		`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _registerToEvent = async (registerEventObject) => {

		console.log("registerEventObject",registerEventObject);
		
		const requestBody = `
			mutation {
				registerEvent(
					${registerEventObject.guestName ? `guest_name: "${registerEventObject.guestName}"` : ''}
					${registerEventObject.guestLastName ? `guest_lastname: "${registerEventObject.guestLastName}"` : ''}
					${registerEventObject.guestDob ? `guest_birthday: "${registerEventObject.guestDob}"` : ''}
					${registerEventObject.guestGender ? `guest_gender: "${registerEventObject.guestGender}"` : ''}
					${registerEventObject.guestEmail ? `guest_email: "${registerEventObject.guestEmail}"` : ''}
					${registerEventObject.guestMobileNumber ? `guest_mobile_number: "${registerEventObject.guestMobileNumber}"` : ''}
					event_date_id: "${registerEventObject.eventId}"
				) {
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
					event_date {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
					}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
					event_date_date
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
					}
					user_id
				}		 
			}`;


		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query);

		return request;
	}

	const _registerToLounge = async (registerEventObject) => {

			const requestBody = `
			mutation {
				registerEventHour(
					${registerEventObject.guestName ? `guest_name: "${registerEventObject.guestName}"` : ''}
					${registerEventObject.guestLastName ? `guest_lastname: "${registerEventObject.guestLastName}"` : ''}
					${registerEventObject.guestDob ? `guest_birthday: "${registerEventObject.guestDob}"` : ''}
					${registerEventObject.guestGender ? `guest_gender: "${registerEventObject.guestGender}"` : ''}
					${registerEventObject.guestEmail ? `guest_email: "${registerEventObject.guestEmail}"` : ''}
					${registerEventObject.guestMobileNumber ? `guest_mobile_number: "${registerEventObject.guestMobileNumber}"` : ''}
					event_hour_id: "${registerEventObject.eventHourID}"
				 ){
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
					event_date {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
					}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
					event_hour {
						created_at
						date
						deleted_at
						end_time
						id
						max_attendees
						start_time
						status
				   	}
					event_date_date
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
					}
					user_id
				}
			   }`;
	
	
			const query = JSON.stringify({ query: requestBody });
	
			const request = await _makePostRequest(query);
	
			return request;
	}

	const _showEvent = async (id) => {

        const requestBody = `
            query{
				ShowEvent(show_one: ${id}) {
				  category {
					id
					name
				  }
				  cover
				  created_at
				  date_publish
				  dates {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
				  }
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
					  }
					max_attendees
					note
					status
				  }
				  deleted_at
				  description
				  id
				  name
				  short_description
				  type
				}
			  }
			  
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	const _updateRegisterEvent = async (event_date_id) => {

        const requestBody = `
			mutation {
				updateRegisterEvent(event_date_id: "${event_date_id}") {
				attendance_date
				cancel_date
				created_at
				event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
				}
				event_date {
					created_at
					date
					deleted_at
					hours{
					created_at
					date
					deleted_at
					end_time
					id
					max_attendees
					start_time
					status
				}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
					}
					max_attendees
					note
					status
				}
				event_date_date
				guest_birthday
				guest_document
				guest_email
				guest_gender
				guest_lastname
				guest_mobile_number
				guest_name
				id
				loyalty_document
				qr_code_image
				qr_code_link
				qr_code_uuid
				registration_date
				status
				user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
				}
				user_id
				}
			}
  
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	const _updateRegisterEventHours = async (event_hour_id) => {

        const requestBody = `
			mutation {
				updateRegisterEventHours(event_hour_id: "${event_hour_id}"){
					attendance_date
					cancel_date
					created_at
					event {
					cover
					created_at
					date_publish
					deleted_at
					description
					id
					name
					short_description
					type
					}
					event_date {
					created_at
					date
					deleted_at
					hours{
								created_at
								date
								deleted_at
								end_time
								id
								max_attendees
								start_time
								status
						}
					id
					location{
						id 
						name 
						category 
						place 
						longitude 
						latitude 
						short_description 
						description 
						created_at 
						city 
						}
					max_attendees
					note
					status
					}
						event_hour {
										created_at
										date
										deleted_at
										end_time
										id
										max_attendees
										start_time
										status
								}
					event_date_date
					guest_birthday
					guest_document
					guest_email
					guest_gender
					guest_lastname
					guest_mobile_number
					guest_name
					id
					loyalty_document
					qr_code_image
					qr_code_link
					qr_code_uuid
					registration_date
					status
					user {
					birthday
					created_at
					email
					email_verified_at
					firstname
					gender
					id
					idm_id
					lastname
					loyalty_card_code
					loyalty_card_expiration_date
					loyalty_id
					mobile_number
					updated_at
					}
					user_id
				}
			}
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	const _yotiFaceScan = async (photo, yoti_token, device) => {

        const requestBody = `
			mutation {
                yotiFaceScan(photo: "${photo}", yoti_token: "${yoti_token}", device: ${device}) {
                    auth_token
                    can_retry
                    error_message
                    is_age_verified
                    remaining_attempts
                    status
                    __typename
                }
            }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	const _yotiStartDocScan = async (yoti_token) => {

        const requestBody = `
			mutation {
                yotiStartDocScanSession(yoti_token: "${yoti_token}") {
                    iframe_url
                    session_id
                    session_token
                }
            }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	const _checkDeliveryOptions = async (city, street, zipcode, product_sku) => {

        const requestBody = `
            query checkDeliveryOptions {
                checkDeliveryOptions(
                    street: "${street}"
                    city: "${city}"
                    zipcode: "${zipcode}"
                    product_sku: "${product_sku}"
                ) {
                    glovo_delivery
                    sameday_delivery
                }
            }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

    const _yotiCompleteDocScan = async (yoti_token, session_id) => {

        const requestBody = `
			mutation {
                yotiCompleteDocScan(
                    session_id: "${session_id}",
                    yoti_token: "${yoti_token}"
                ) {	
                    is_age_verified
                    status
                    can_retry
                    auth_token
                    message
                }
            }
		`;

        const query = JSON.stringify({ query: requestBody });

        const request = await _makePostRequest(query);

        return request;
    }

	_constructor();

	// output/public
	return {
		createEmptyCart: _createEmptyCart,
		addItemToCart: _addItemToCart,
		eraseCommerceCookie: _eraseCommerceCookie,
		updateItemInCart: _updateItemInCart,
		getCart: _getCart,
		getCartQuantity: _getCartQuantity,
		removeItemFromCart: _removeItemFromCart,
		getProduct: _getProduct,
		priceFormatter: _priceFormatter,
		getProductCatalog: _getProductCatalog,
		getCategoryList: _getCategoryList,
		getProductCategoryList: _getProductCategoryList,
		getProductAggregations: _getProductAggregations,
		getStrengthTable: _getStrengthTable,
		setAuthToken: _setAuthToken,
		loginCustomer: _loginCustomer,
		logoutCustomer: _logoutCustomer,
		setSessionStorage: _setSessionStorage,
		getSessionStorage: _getSessionStorage,
		getProductReviews: _getProductReviews,
		getReviewsMetadata: _getReviewsMetadata,
		addReview: _addReview,
		guestSubscribeNewsletter: _guestSubscribeNewsletter,
		qrNewsletterCharging: _qrNewsletterCharging,
		getPsnOptions: _getPsnOptions,
		getPsnMetadata: _getPsnMetadata,
		getCustomerDetails: _getCustomerDetails,
		walletCartCheck: _walletCartCheck,
		clearCartAndAddItem: _clearCartAndAddItem,
		getProductAlerts: _getProductAlerts,
		addProductAlert: _addProductAlert,
		getLoyaltyRestrictedSkus: _getLoyaltyRestrictedSkus,
		getLivechatConfig: _getLivechatConfig,
		createCustomerV2: _createCustomerV2,
		createCustomerChatBot: _createCustomerChatBot,
		checkLogin: _checkLogin,
		customerPaymentTokens: _customerPaymentTokens,
		removeCustomerPaymentTokens: _removeCustomerPaymentTokens,
		createCustomerAddress: _createCustomerAddress,
		deleteCustomerAddress: _deleteCustomerAddress,
		updateCustomerAddress: _updateCustomerAddress,
		revokeCustomerToken: _revokeCustomerToken,
		changeCustomerPassword: _changeCustomerPassword,
		requestPasswordResetEmail: _requestPasswordResetEmail,
		resetPassword: _resetPassword,
		deleteCustomer: _deleteCustomer,
		orderHistory: _orderHistory,
		getOrderInfo: _getOrderInfo,
		getOrdersOfPage: _getOrdersOfPage,
		getBlogPosts: _getBlogPosts,
		blogPostInfo: _blogPostInfo,
		blogPostActionLike: _blogPostActionLike,
		blogPostActionVideo: _blogPostActionVideo,
		createCustomerChatBot: _createCustomerChatBot,
		changeCustomerPreferences: _changeCustomerPreferences,
		loyaltyPointsAssignment: _loyaltyPointsAssignment,
		loyaltyServiceUpdate: _loyaltyServiceUpdate,
		loyaltyUserInfo: _loyaltyUserInfo,
		loyaltyUserTier: _loyaltyUserTier,
		syncOfflineDevices: _syncOfflineDevices,
		pointsHistory: _pointsHistory,
		createFanizeLoyaltyUser: _createFanizeLoyaltyUser,
		getMissionList: _getMissionList,
		getQuestions: _getQuestions,
		getPredictions: _getPredictions,
		getQuizQuestions: _getQuizQuestions,
        getQuizDetails: _getQuizDetails,
        saveQuiz: _saveQuiz,
		getQuizPoints: _getQuizPoints,
		getQuizPointsPrelive: _getQuizPointsPrelive,
		getVideoContent: _getVideoContent,
		saveVideo: _saveVideo,
		getInformationContent: _getInformationContent,
		predictionSubmission: _predictionSubmission,
		saveAnswers: _saveAnswers,
		saveInformation: _saveInformation,
		getDownloadContent: _getDownloadContent,
		getStores: _getStores,
		customerMgmService: _customerMgmService,
		getSocialLoginInfo: _getSocialLoginInfo,
		socialLogin: _socialLogin,
		validateGloNumber: _validateGloNumber,
		registerDevice: _registerDevice,
		deviceRegistration: _deviceRegistration,
		registeredDevicesList: _registeredDevicesList,
		optOutFanizeLoyaltyUser: _optOutFanizeLoyaltyUser,
		otpRequest: _otpRequest,
		otpVerify: _otpVerify,
		getContactId: _getContactId,
		getproductReviewStatus:_getproductReviewStatus,
		salesforceRegistration: _salesforceRegistration,
		getProductReviewsByPage: _getProductReviewsByPage,
		getProductsPrices: _getProductsPrices,
		getProductPrice:_getProductPrice,
		validateCustomer: _validateCustomer,
		getWorldPayInfo: _getWorldPayInfo,
		applyCouponToCart: _applyCouponToCart,
		setWorldPayCCPaymentMethodOnCart: _setWorldPayCCPaymentMethodOnCart,
		setShippingAddressesOnCart: _setShippingAddressesOnCart,
		setFreeShippingMethodOnCart: _setFreeShippingMethodOnCart,
		setMatrixShippingMethodOnCart: _setMatrixShippingMethodOnCart,
		placeOrder: _placeOrder,
		setCoinsPaymentMethodOnCart: _setCoinsPaymentMethodOnCart,
		getPanels: _getPanels,
		pictureUpdate: _pictureUpdate,
		getConfigurableProductsByListOfSKU: _getConfigurableProductsByListOfSKU,
		newOtpRequest: _newOtpRequest,
		newOtpVerify: _newOtpVerify,
		delayedOtpRequest: _delayedOtpRequest,
		delayedOtpVerify: _delayedOtpVerify,
		scandCodes: _scanCodes,
		fanCamStickers: _fanCamStickers,
		fanCamPictureUpload: _fanCamPictureUpload,
		contactUsMetadata: _contactUsMetadata,
        contactUsPostAction: _contactUsPostAction,
		validateEmailAndPhone: _validateEmailAndPhone,
		validateDeviceData: _validateDeviceData,
		customerRegistrationV2Submit3: _customerRegistrationV2Submit3,
		customerRegistrationV2Submit4: _customerRegistrationV2Submit4,
		updateCustomerV2: _updateCustomerV2,
		getGlopinsLocations: _getGlopinsLocations,
		getAllEventsAndLounge: _getAllEventsAndLounge,
		getOneEventAndLounge: _getOneEventAndLounge,
		getRegisteredEventNext: _getRegisteredEventNext,
		getRegisteredEventComing: _getRegisteredEventComing,
        getRegisteredEvents: _getRegisteredEvents,
        gloersContest: _gloersContest,
        gloersContestCheck: _gloersContestCheck,
        getNotifications: _getNotifications,
        readNotifications: _readNotifications,
		deleteNotifications: _deleteNotifications,
		registerToEvent:_registerToEvent,
		registerToLounge: _registerToLounge,
		showEvent: _showEvent,
		updateRegisterEvent: _updateRegisterEvent,
		updateRegisterEventHours: _updateRegisterEventHours,
        cancelRegisterEvent: _cancelRegisterEvent,
        cancelRegisterLounge: _cancelRegisterLounge,
        yotiFaceScan: _yotiFaceScan,
        yotiStartDocScan: _yotiStartDocScan,
        yotiCompleteDocScan: _yotiCompleteDocScan,
        checkDeliveryOptions: _checkDeliveryOptions
	};
})();
