const RELEASE_DATES = [
    "2025-01-28T15:00:00", // Episodio 1
    "2025-01-31T15:00:00", // Episodio 2
    "2025-02-04T15:00:00", // Episodio 3
    "2025-02-06T15:00:00", // Episodio 4
    "2025-02-11T15:00:00", // Episodio 5
    "2025-02-13T15:00:00", // Episodio 6
    "2025-02-18T15:00:00", // Episodio 7
    "2025-02-20T15:00:00"  // Episodio 8
];

const AudioMisteryUtils = (() => {

    const _timeRemaining = (targetDate) => {
        const currentDate = new Date();
        const timeDiff = new Date(targetDate) - currentDate;

        if (timeDiff <= 0) {
            return { isReleased: true };
        }

        const _days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const _hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const _minutes = Math.ceil((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        const days = String(_days).padStart(2, '0');
        const hours = String(_hours).padStart(2, '0');
        const minutes = String(_minutes).padStart(2, '0');

        return { isReleased: false, days, hours, minutes };
    };

    const _getVisibleEpisodes = (episodes) => {
        const currentDate = new Date();

        return episodes.filter((episode, index) => {
            const releaseDate = new Date(RELEASE_DATES[index]); 
            return currentDate >= releaseDate; 
        });
    };

    const _getNextEpisode = () => {
        for (let i = 0; i < RELEASE_DATES.length; i++) {
            const countdown = _timeRemaining(RELEASE_DATES[i]);
            if (!countdown.isReleased) {
                return { episodeIndex: i + 1, countdown };
            }
        }
        return null; // Tutti gli episodi sono stati rilasciati
    };

    const _isThirdEpisodeReleased = () => {
        // CORRETTO CON L'USCITA DEL SECONDO EPISODIO. 
        const currentDate = new Date();
        const secondEpisodeDate = new Date(RELEASE_DATES[1]); 
        // Restituisce true se il terzo episodio è già uscito
        return currentDate >= secondEpisodeDate;
    };
    

    const _constructor = () => {
        // console.log('Config is running');
     };
 
     _constructor();

    return {
        timeRemaining: _timeRemaining,
        getVisibleEpisodes: _getVisibleEpisodes,
        getNextEpisode: _getNextEpisode,
        isThirdEpisodeReleased:_isThirdEpisodeReleased,
    };
})();
